import * as React from 'react';
import PageContainer from '../../common/PageContainer';
import { Grid, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useUserSummary } from '../../../utils/services/consumers.api';
import {
  PageContent,
  PageFooter,
  ScrollableQuinnContainer,
} from '../../common/ScrollableContainer';
import DecodedButton from '../../common/DecodedButton';
import Typography from '@mui/material/Typography';
import { TextBody2Standard } from '../../shared/Typography';

const useIntroStepStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: '1 1 auto',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  skip: {
    color: theme.palette.primary.main,
    fontSize: '1.6rem',
  },
  skipButton: {
    color: theme.palette.accent.main,
    textDecoration: 'underline',
    fontSize: '1.6rem',
    marginBottom: '0.5rem',
  },
}));

export const IntroStep = ({ onScanID, onSkip, handleBack, userFirstName, existing }) => {
  const user = useUserSummary();
  const classes = useIntroStepStyles();
  if (existing) {
    return (
      <PageContainer loading={false} onBack={handleBack}>
        <ScrollableQuinnContainer
          messageId="identity.document.scan.document.existing.bubble"
          message={`Hi${
            user ? ` ${user.name.given}` : ` ${userFirstName}`
          }, would you like to upload a new driver's license or state issued identification card?`}
        >
          <PageContent></PageContent>
          <PageFooter>
            <Grid xs={12} item>
              <DecodedButton onClick={onScanID}>Scan ID</DecodedButton>
            </Grid>
            <Grid xs={12} style={{ marginTop: '12px' }} item>
              <TextBody2Standard primary>
                {' '}
                Click{' '}
                <Link
                  component="button"
                  variant="body1"
                  className={classes.skipButton}
                  onClick={onSkip}
                >
                  here
                </Link>{' '}
                to skip if you cannot update your identification card.
              </TextBody2Standard>
            </Grid>
          </PageFooter>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }

  return (
    <PageContainer loading={false} onBack={handleBack}>
      <ScrollableQuinnContainer
        messageId="registration.scan.document.intro"
        message={`Please scan your driver’s license or state identification card.`}
      >
        <PageContent></PageContent>
        <PageFooter>
          <Grid xs={12} item>
            <DecodedButton onClick={onScanID}>Scan ID</DecodedButton>
          </Grid>
          <Grid xs={12} style={{ marginTop: '12px' }} item>
            <TextBody2Standard primary>
              {' '}
              Click{' '}
              <Link
                component="button"
                variant="body1"
                className={classes.skipButton}
                onClick={onSkip}
              >
                here
              </Link>{' '}
              to skip if you cannot update your identification card.
            </TextBody2Standard>
          </Grid>
        </PageFooter>
      </ScrollableQuinnContainer>
    </PageContainer>
  );
};
