import { Component } from 'react';

export default class DecodedComponent extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.stateSubscription = this.bloc.subscribeToState(this.__handleState);
    this.eventSubscription = this.bloc.subscribeToEvents(this.__handleEvent);
  }

  componentWillUnmount() {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }

  __handleState = (state) => {
    this.setState({
      ...state,
    });
  };

  __handleEvent = (event) => {};
}
