import React from 'react';

import withStyles from '@mui/styles/withStyles';
import { Redirect, withRouter } from 'react-router-dom';
import PageContainer from '../common/PageContainer';
import { Grid } from '@mui/material';
import DecodedComponent from '../shared/DecodedComponent';
import { Bloc } from './bloc';
import { ScrollableQuinnContainer } from '../common/ScrollableContainer';
import { H1TextTitleContent } from '../shared/Typography';
import { FormattedMarkdown } from '@decodedhealth/react-library';

const styles = (theme) => ({});

const initState = {
  initialising: true,
};

class ThankYou extends DecodedComponent {
  constructor(props) {
    super(props);
    this.state = { ...initState };
    this.bloc = new Bloc();
  }

  render() {
    const { initialising } = this.state;

    return (
      <PageContainer loading={initialising}>
        <ScrollableQuinnContainer messageId={'generic.thankyou.bubble'} message={'Thank you'}>
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ margin: '64px 0' }}>
              <H1TextTitleContent>
                <FormattedMarkdown id={'generic.thankyou.bubble'} defaultMessage={``} />
              </H1TextTitleContent>
            </Grid>
            {!initialising && <Redirect to="/" />}
          </Grid>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(withRouter(ThankYou));
