import { Box, styled } from '@mui/material';

export const Bullseye = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  margin: '0',
});
