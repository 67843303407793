import * as React from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { OTPublisher } from 'opentok-react';
import {
  useDebounceCallback,
  AudioMeter,
  CamButton,
  MicButton,
} from '@decodedhealth/react-library';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '10px',
  },
  publisherWrapper: {
    height: '250px',
  },
  disabledPublisher: {
    height: '250px',
    backgroundColor: theme.palette.action.disabled,
  },
  avActions: {
    marginLeft: '15px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  fab: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

export const Publisher = ({ session, onError, onPublish, onInit, disabled }) => {
  const classes = useStyles();
  const [isCamOn, setIsCamOn] = React.useState(true);
  const [isMicOn, setIsMicOn] = React.useState(true);
  const [updatedAudioLevel, setUpdatedAudioLevel] = React.useState(0);
  /**
   * logic for automatically averaging the audio level logarithmically
   * https://tokbox.com/developer/sdks/js/reference/Publisher.html#events
   */
  let movingAvg = null;
  const handleUpdatedAudioLevelEvent = useDebounceCallback((audioLevel) => {
    if (movingAvg === null || movingAvg <= audioLevel) {
      movingAvg = audioLevel;
    } else {
      movingAvg = 0.7 * movingAvg + 0.3 * audioLevel;
    }

    // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
    let logLevel = Math.log(movingAvg) / Math.LN10 / 1.5 + 1;
    logLevel = Math.min(Math.max(logLevel, 0), 1);
    setUpdatedAudioLevel(Math.floor(logLevel * 10));
  }, []);

  const toggleCam = React.useCallback(() => {
    setIsCamOn((camOn) => !camOn);
  }, []);

  const toggleMic = React.useCallback(() => {
    setIsMicOn((micOn) => !micOn);
  }, []);

  return (
    <Grid container className={classes.root} direction="row" alignItems="flex-end">
      <Grid item className={classes.publisherWrapper} xs={4} sm={3} md={3}>
        {!disabled ? (
          <OTPublisher
            properties={{
              style: {
                audioLevelDisplayMode: 'off',
                buttonDisplayMode: 'off',
              },
              width: '100%',
              height: '100%',
              resolution: '1280x720',
              publishAudio: isMicOn,
              publishVideo: isCamOn,
            }}
            session={session}
            style={{ width: '100%', height: '100%' }}
            onError={onError}
            onPublish={onPublish}
            onInit={onInit}
            eventHandlers={{
              audioLevelUpdated: (event) => {
                handleUpdatedAudioLevelEvent(event.audioLevel);
              },
            }}
          />
        ) : (
          <div className={classes.disabledPublisher} />
        )}
      </Grid>
      <Grid container item className={classes.avActions} xs={7} sm={6} md={6}>
        <Grid item>
          <CamButton
            isCamOn={isCamOn}
            onClick={toggleCam}
            disabled={disabled}
            className={classes.fab}
          />
          <MicButton
            isMicOn={isMicOn}
            onClick={toggleMic}
            disabled={disabled}
            className={classes.fab}
          />
        </Grid>
        <Grid item>
          <AudioMeter level={updatedAudioLevel} />
        </Grid>
      </Grid>
    </Grid>
  );
};
