import { FormControl, Grid, InputLabel, MenuItem, Select, styled } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FLAGS from 'country-flag-icons/react/3x2';
import { TextValidator } from 'react-material-ui-form-validator';
import { PhoneTextMask } from '../InputTextMask';
import { COUNTRY_OPTIONS } from '../../../utils/country';

export const PhoneNumber = ({
  codeValue,
  numberValue,
  numberLabel,
  onCodeChange,
  onNumberChange,
  selectedCountry,
  numberRequired = false,
  codeRequired = false, disabled = false,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3} sm={4}>
        <FormControl fullWidth disabled={disabled}>
          <InputLabel id="code-label">
            <FormattedMessage id="registration.field.label.code" defaultMessage={'Code'} />
          </InputLabel>
          <Select
            id="code"
            name="code"
            fullWidth
            required={codeRequired}
            validators={['required']}
            label="code-label"
            value={codeValue}
            renderValue={(value) => {
              const Flag = FLAGS[value.code];
              return (
                <>
                  {Boolean(Flag) ? <Flag style={{ width: '20px', marginRight: '5px' }} /> : null}{' '}
                  {value.callingCode}
                </>
              );
            }}
            onChange={onCodeChange}
          >
            {COUNTRY_OPTIONS.map(({ callingCode, name, code }) => {
              const Flag = FLAGS[code];
              const Item = styled(MenuItem)({ '&:hover': { color: '#ffffff' } });
              return (
                <Item key={`${callingCode}-${code}`} value={{ callingCode, code }}>
                  <Flag style={{ width: '20px', marginRight: '5px' }} /> {callingCode} ({name})
                </Item>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={9} sm={8}>
        <TextValidator
          fullWidth
          id="number"
          label={numberLabel}
          required={numberRequired}
          name="number"
          autoComplete="off"
          inputProps={{
            autoComplete: 'off',
            inputMode: 'tel',
          }}
          value={numberValue}
          onChange={(e) => {
            selectedCountry === 'US'
              ? onNumberChange(e)
              : e.target.value.length <= 13 && onNumberChange(e);
          }}
          placeholder="e.g. (202) 555-1234"
          validators={
            selectedCountry === 'US'
              ? ['matchRegexp:^(\\([0-9]{3}\\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$']
              : ['matchRegexp:^[0-9]*$']
          }
          errorMessages={[
            <FormattedMessage
              id="registration.field.error.mobile"
              defaultMessage={'Invalid phone number'}
            />,
          ]}
          InputProps={
            selectedCountry === 'US'
              ? {
                  inputComponent: PhoneTextMask,
                }
              : undefined
          }
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};
