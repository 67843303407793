import axios from 'axios';

class EventApi {
  constructor() {
    this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || '';
  }

  publish(event) {
    return axios.post(`${this.baseUrl}/api/consumers/me/_events`, event);
  }
}

export const eventApi = new EventApi();
