import * as rxjs from 'rxjs';
import { notificationService } from '../../../utils/notification';
import { userInfoUtil } from '../../../utils/user';
import { phoneUtil } from '../../../utils/phone';
import { providerStorage } from '../../../utils/provider.qs';

const initial = {
  initialised: false,
};

export class Bloc {
  constructor(props, callback) {
    this.subject = new rxjs.BehaviorSubject({
      ...initial,
      ...props,
    });

    this.events = new rxjs.Subject();

    this.callback = callback;
  }

  __updateSubject = (value) =>
    this.subject.next({
      ...this.subject.value,
      ...value,
    });

  __publishEvent = (type, data) =>
    this.events.next({
      type: type,
      data: data,
    });

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  initialise = () => {
    setTimeout(() => {
      console.log('Redirecting');
      window.location = 'https://www.medicbuddy.com/webchat/';
    }, 3000);
    providerStorage.clearProvider();
  };
}

export class Constants {}

export class BlocEvent {}
