import { FormattedMarkdown } from '@decodedhealth/react-library';
import { Grid, Typography } from '@mui/material';
import React, { Component } from 'react';
import { H2TextTitleContent } from '../../shared/Typography';
import withStyles from "@mui/styles/withStyles";

const GridItem = (props) => <Grid {...props} item />;

const styles = (theme) => ({
    root: {},
});

class Confirmation extends Component {

    render() {

        return (
            <Grid container direction="column">
                <H2TextTitleContent component={GridItem}>
                    <FormattedMarkdown
                        id="interaction.appointment.reservation.confirmation.heading"
                        defaultMessage="**Your reserved place in line is not an appointment.  For your convenience, we will notify you via text if we are experiencing any delays.**"
                    />
                </H2TextTitleContent>
                <H2TextTitleContent component={GridItem}>
                    <FormattedMarkdown
                        id="interaction.appointment.reservation.confirmation.message1"
                        defaultMessage="Please note that we make every effort to see you promptly, however, wait times may vary.  Patients with serious conditions may be seen first."
                    />
                </H2TextTitleContent>
                <H2TextTitleContent component={GridItem}>
                    <FormattedMarkdown
                        id="interaction.appointment.reservation.confirmation.message2"
                        defaultMessage="We appreciate your patience and understanding."
                    />
                </H2TextTitleContent>
            </Grid>
        );
    }
}

export default withStyles(styles)(Confirmation);
