import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { DecodedButton } from '../../../common/DecodedButton';
import PageContainer from '../../../common/PageContainer';
import {
  DefaultDisconnectedPageFooter,
  ScrollableQuinnContainer,
} from '../../../common/ScrollableContainer';
import { H2TextTitleContent } from '../../../shared/Typography';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import DecodedComponent from '../../../shared/DecodedComponent';
import { AnalyticsEvent, analyticsEventLogger } from '../../../../utils/events';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

class UnknownInsurance extends DecodedComponent {
  constructor(props) {
    super(props);
    this.bloc = props.bloc;
    this.state = { ...this.bloc.subject.value };
    analyticsEventLogger.log(AnalyticsEvent.PAYMENT_METHOD_UNSURE_LOADED, { action: 'unknown' });
  }

  __continue = () => {
    this.bloc.clearInsuranceAndSave();
  };

  render() {
    let { loading, isWalkin } = this.state;

    return (
      <PageContainer
        loading={loading}
        onBack={() => {
          if (!loading) {
            this.props.clearComponent();
          }
        }}
      >
        <ScrollableQuinnContainer
          messageId="insurance.notfound.bubble"
          message="Unable to find your insurance or unsure?"
          size={'large'}
        >
          <Grid item xs={12}>
            <H2TextTitleContent>
              {isWalkin ? (
                <FormattedMarkdown
                  id={`insurance.notfound.walkin.text`}
                  defaultMessage={`**Please finish checking in then bring your insurance card to the front desk.**`}
                />
              ) : (
                <FormattedMarkdown
                  id={`insurance.notfound.text`}
                  defaultMessage={`**Please complete your booking and make sure to bring your insurance card with you.**`}
                />
              )}
            </H2TextTitleContent>
          </Grid>
        </ScrollableQuinnContainer>
        <DefaultDisconnectedPageFooter justifyContent={'left'}>
          <Grid item xs={12} sm={6}>
            <DecodedButton onClick={this.__continue}>
              {isWalkin ? (
                <FormattedMessage
                  id="insurance.form.button.label.submit"
                  defaultMessage={'Continue check in'}
                />
              ) : (
                <FormattedMessage
                  id="insurance.form.walkin.button.label.submit"
                  defaultMessage={'Continue booking'}
                />
              )}
            </DecodedButton>
          </Grid>
        </DefaultDisconnectedPageFooter>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(UnknownInsurance);
