import { FormattedMarkdown } from '@decodedhealth/react-library';
import { Backdrop, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { isAfter } from 'date-fns';
import * as React from 'react';
import {  BarChart, Bar, CartesianGrid, Cell, ResponsiveContainer, XAxis, YAxis } from 'recharts';

const TimeBarChart = ({ schedulingIntervals }) => {
  const theme = useTheme();
  const availableIntervals = React.useMemo(
    () =>
      schedulingIntervals.filter((interval) => {
        const now = new Date();
        return interval.capacity > 0 && isAfter(interval.parsedTime, now);
      }),
    [schedulingIntervals],
  );
  const showTooltip = availableIntervals.length > 0;
  const showNoSsessions = !availableIntervals.length > 0;
  const data = availableIntervals.length > 0 ? schedulingIntervals : availableIntervals;

  return (
    <Grid
      item
      row
      xs={12}
      sx={{
        position: 'relative',
        width: '700px',
        minWidth: '90%',
        maxWidth: '700px',
        minHeight: '140px',
        maxHeight: '300px',
        [theme.breakpoints.down('md')]: {
            minHeight: '140px',
            maxHeight: '140px',
            maxWidth: '70vw',
        },
        [theme.breakpoints.down('sm')]: {
          minHeight: '140px',
          maxHeight: '140px',
          maxWidth: '85vw',
        },
      }}
    >
        { data.length > 0 ?
      <ResponsiveContainer width="100%" height="100%" minHeight={"140px"}>
        <BarChart data={data} width={500}
                  height={300} margin={{ top: 5, right: 10, bottom: 5, left: 0 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="hourDisplay" interval="preserveStartEnd"  />
          <YAxis width={40} ticks={[0, 10, 20, 30, 40, 50, 60]} />
          <Tooltip cursor={showTooltip} active={showTooltip} />
          <Bar
            isAnimationActive={false}
            name="Wait Time (minutes)"
            dataKey="waitTimes"
            fill={theme.palette.accent.main}
           >
            {data.map((entry, index) => (
              <Cell fill={theme.palette.accent.main} key={`cell-${index}`} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer> : <></> }

      <div>
        {showNoSsessions && (
          <Backdrop open={true}>
            <Typography variant="body2" component={'div'}>
              <FormattedMarkdown
                id={'booking.appointment.schedule.chart.unavailable'}
                defaultMessage={'No session available'}
              />
            </Typography>
          </Backdrop>
        )}
      </div>
    </Grid>
  );
};

export default TimeBarChart;
