import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import { uriStorage } from '../../../utils/storage';
import PaymentConfirmation from './PaymentConfirmation';

const styles = (theme) => ({});

const initState = {
  loading: false,
  activeStep: 0,
};

class PaymentCheck extends Component {
  constructor(props) {
    super(props);
    this.state = initState;
  }

  componentDidMount() {
    uriStorage.setCurrentPath(this.props.match.url);
  }

  handleNext = () => {
    this.setState((state) => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  setLoading = (value) => {
    this.setState({
      loading: value,
    });
  };

  getStepContent = (step) => {
    let { appointmentId, appointmentType } = this.props.match.params;

    switch (step) {
      case 0:
        return (
          <PaymentConfirmation
            {...this.state}
            {...{ appointmentId, appointmentType }}
            setLoading={this.setLoading}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  };

  render() {
    let { activeStep } = this.state;

    return this.getStepContent(activeStep);
  }
}

export default withStyles(styles)(PaymentCheck);
