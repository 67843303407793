import Button from '@mui/material/Button';
import { styled } from '@mui/system';

export const TextButton = styled(Button)({
    '&:hover': {
      backgroundColor: 'transparent', 
    },
    '&:focus': {
      outline: 'none', 
      boxShadow: 'none', 
    },
    '&:active': {
      boxShadow: 'none', 
    },
  });