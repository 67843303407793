import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import DecodedComponent from '../../../shared/DecodedComponent';
import InsuranceContext from '../context';
import { withRouter } from 'react-router-dom';
import ChatBubble from '../../../shared/ChatBubble';
import { Button, Grid } from '@mui/material';
import { Loading } from '../../../../shared-library';
import PageContainer from '../../../common/PageContainer';
import { ScrollableQuinnContainer } from '../../../common/ScrollableContainer';
import { AnalyticsEvent, analyticsEventLogger } from '../../../../utils/events';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

class InsuranceLoading extends DecodedComponent {
  constructor(props) {
    super(props);

    analyticsEventLogger.log(AnalyticsEvent.PAYMENT_METHOD_REVIEW_OPEN, {});
    this.bloc = props.context.bloc;
    this.state = {};
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <PageContainer
        loading={false}
        onBack={() => {
          this.props.history.goBack();
        }}
      >
        <ScrollableQuinnContainer
          messageId="insurance.loading.bubble"
          message="Thank you. Let me just check for any insurance on file."
        >
          <Grid container direction="column" justifyContent="space-between">
            <Loading isFullscreen={false} />
          </Grid>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(
  withRouter((props) => (
    <InsuranceContext.Consumer>
      {(value) => {
        return <InsuranceLoading context={value} {...props} />;
      }}
    </InsuranceContext.Consumer>
  )),
);
