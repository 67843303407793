import React from 'react';
import withStyles from '@mui/styles/withStyles';
import DecodedComponent from '../shared/DecodedComponent';
import { withRouter } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Loading } from '../../shared-library';
import PageContainer from '../common/PageContainer';

import { ScrollableQuinnContainer } from '../common/ScrollableContainer';
import { Bloc, BlocEvent } from './bloc';
import { appointmentApi } from '../../utils/services/appointments.api';
import { providerStorage } from '../../utils/provider.qs';
import { H2TextTitleContent } from '../shared/Typography';
import { FormattedMarkdown } from '@decodedhealth/react-library';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

class VipRouting extends DecodedComponent {
  constructor(props) {
    super(props);
    const { appointmentId, vipType } = this.props.match.params;
    const organisationId = providerStorage.getCurrentProvider() || '';
    this.bloc = new Bloc({
      organisationId: organisationId === '' ? undefined : organisationId,
      appointmentId: appointmentId,
      vipType: vipType,
      appointmentApi: appointmentApi,
    });
    this.state = {
      initialising: true,
      vipType: vipType,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.bloc.initialise();
  }

  __handleEvent = (event) => {
    const { type, data } = event;

    if (type === BlocEvent.NAVIGATE_TO) {
      this.props.history.replace(data.url);
    }
  };

  render() {
    const { initialising, vipType } = this.state;

    if (!initialising) {
      return this.__renderType(vipType);
    }

    return (
      <PageContainer loading={initialising} hideBack={true}>
        <ScrollableQuinnContainer
          messageId={`vip.routing.notice.bubble`}
          message="Redirecting to urgentcare."
        >
          <Grid container direction="column" justifyContent="space-between" flex="1">
            <Loading isFullscreen={false} />
          </Grid>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }

  __renderType = (type) => {
    return (
      <PageContainer loading={false} hideBack={true}>
        <ScrollableQuinnContainer
          messageId={`vip.routing.${type}.notice.bubble`}
          message="Thank you for completing the registration. Please go to the front desk."
        >
          <Grid container direction="column" justifyContent="space-between" flex="1">
            <H2TextTitleContent>
              <FormattedMarkdown
                id={`vip.routing.${type}.notice.text`}
                defaultMessage={`Please tell our front desk the company you work for and we will secure your place in line.`}
              />
            </H2TextTitleContent>
          </Grid>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  };
}

export default withStyles(styles)(withRouter(VipRouting));
