import { DecodedBloc } from '../shared/DecodedComponent/bloc';
import { providerStorage } from '../../utils/provider.qs';
import { LOGIN_ROUTE, ROOT_ROUTE } from '../../utils/route.name';
import { systemApi } from '../../utils/services/system.api';
import { notificationService } from '../../utils/notification';
import { administrationApi } from '../../utils/services/administration.api';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';

const TOKEN_KEY = 'kiosk-token';
const TOKEN_EXPIRE_KEY = 'kiosk-token-expire';

export class Bloc extends DecodedBloc {
  checkPinPoller;

  constructor(props) {
    super({});

    this.__initialise();
    this.history = props.history;

    this.startPinCheckPoller = this.startPinCheckPoller.bind(this);
  }

  __initialise = async () => {
    const token = sessionStorage.getItem(TOKEN_KEY) || '';
    const expire = sessionStorage.getItem(TOKEN_EXPIRE_KEY) || '';
    if (token) {
      administrationApi.createPin();
    }

    systemApi.providers().then(
      (data) => {
        this.__updateSubject({
          initialising: false,
          providers: data.data.locations,
          providerId: providerStorage.getPersistedProvider(),
        });
      },
      (reason) => notificationService.httpError(reason),
    );
  };

  requestPin = () => {
    const { handle } = this.subject.value;
    this.__updateSubject({ busy: true });
    administrationApi
      .createPin(handle)
      .then(
        (value) => {
          const token = value.data.token;
          this.__updateSubject({
            pin: value.data.pin,
            pollToken: token,
          });
          this.startPinCheckPoller(token);
        },
        (reason) => {
          notificationService.httpError(reason);
        },
      )
      .finally(() => {
        this.__updateSubject({ busy: false });
      });
  };

  stopPinCheckPoller = () => {
    clearInterval(this.checkPinPoller);
  };

  startPinCheckPoller = (token) => {
    this.stopPinCheckPoller();
    this.checkPinPoller = setInterval(() => {
      administrationApi
        .checkPin(token)
        .then(
          (value) => {
            const token = value.data.token;
            const expire = value.data.expire;
            this.setToken(token, expire);
            this.__updateSubject({
              token: token,
              tokenExpire: expire,
            });
            this.stopPinCheckPoller();
            this.__publishEvent(BlocEvent.PIN_VERIFIED, {});
          },
          (reason) => {},
        )
        .finally(() => {
          this.__updateSubject({ busy: false });
        });
    }, 3000);
  };

  setToken = (token, expire) => {
    sessionStorage.setItem(TOKEN_KEY, token);
    sessionStorage.setItem(TOKEN_EXPIRE_KEY, expire);
  };

  setLocation = async (providerId) => {
    let newState = {
      providerId: providerId,
    };
    this.__updateSubject({ ...newState });
  };

  setValue = (key, value) => {
    let newState = {};
    newState[key] = value;
    this.__updateSubject({ ...newState });
  };

  save = () => {
    const { providerId, terminalId, terminalLocation } = this.subject.value;
    providerStorage.setPersistent(providerId, terminalId, terminalLocation);
    sessionStorage.removeItem(TOKEN_KEY);
    sessionStorage.removeItem(TOKEN_EXPIRE_KEY);
    this.history.push(LOGIN_ROUTE);
  };

  clear = () => {
    this.__updateSubject({ providerId: '', terminalId: '' });
  };
}

export class BlocEvent {
  static INITIALISED = 'INITIALISED';
  static PIN_VERIFIED = 'PIN_VERIFIED';
}
