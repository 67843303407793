import React from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';

import Encounter from './Encounter';
import VirtualClinicContext from './context';
import { PrivateRoute } from '../../../utils/protected.route';
import PreCall from './WaitingRoom/Precall';

const styles = (theme) => ({
  root: {
    display: 'flex',
    maxHeight: '100vh',
    height: '100%',
    width: '100%',
    overscrollBehavior: 'contain',
    touchAction: 'none',
  },
  drawerContent: {
    paddingLeft: '20px',
    width: '100%',
  },
});

class VirtualClinic extends React.Component {
  subscription;

  state = {
    idle: false,
    open: false,
    user: {},

    telehealth: {
      state: '',
      video: true,
      audio: true,
    },
  };

  constructor(props) {
    super(props);
  }

  _handleEvent = (event) => {};

  render() {
    const { classes } = this.props;

    const { encounter } = this.state;

    let routes = (
      <>
        <PrivateRoute path="/virtual-clinic/waiting-room/:appointmentId" component={PreCall} />
        <PrivateRoute path="/virtual-clinic/encounter/:encounterId" component={Encounter} />
      </>
    );

    let context = {
      encounter: encounter,
    };

    return (
      <VirtualClinicContext.Provider value={context}>
        <div className={classes.root}>{routes}</div>
      </VirtualClinicContext.Provider>
    );
  }
}

export default withStyles(styles)(withRouter(VirtualClinic));
