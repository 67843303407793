import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';

import { Typography } from '@mui/material';

import { clinicBloc } from '../Secure/VirtualClinic/clinic.bloc';
import CallIcon from '@mui/icons-material/Call';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'fixed',
    top: '105px',
    right: (props) => (props.isChatOpen ? '42%' : '10px'),
    zIndex: '1000',
    backgroundColor: '#808a94b3',
    borderRadius: '30px',
    height: '50px',
    width: '155px',
    padding: '1em',
    [theme.breakpoints.down('sm')]: {
      width: '120px',
      height: '40px',
    },
  },
  title: {
    margin: 'auto',
    fontStyle: 'normal',
    color: '#ffffff',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5em',
    },
  },
  icon: {
    margin: 'auto',
  },
});

class Dialin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialin: {
        name: '',
      },
    };
  }

  componentDidMount() {
    this.blocSubscription = clinicBloc.subscribeToState(this._stateHandler);

    analyticsEventLogger.log(AnalyticsEvent.TELEHEALTH_DIALIN_START, {
      sessionId: this.props.sessionId,
    });
  }

  componentWillUnmount() {
    this.blocSubscription.unsubscribe();

    analyticsEventLogger.log(AnalyticsEvent.TELEHEALTH_DIALIN_END, {
      sessionId: this.props.sessionId,
    });
  }

  _stateHandler = (event) => {
    this.setState({
      dialin: event.dialinInfo,
    });
  };

  render() {
    let { classes } = this.props;
    let { dialin } = this.state;

    if (dialin.name === '' || dialin.name === undefined) return <></>;

    return (
      <div className={classes.root}>
        <CallIcon className={classes.icon} color={'primary'} fontSize={'large'} />
        <Typography className={classes.title} align={'center'} variant={'h5'}>
          {dialin.name?.replace(/'/g, '')}
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(Dialin);
