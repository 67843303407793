import { Checkbox as MuiCheckBox } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { withAccentColor } from './withAccentColor';

const UncheckedIcon = withAccentColor(RadioButtonUncheckedIcon)

const CheckedIcon = withAccentColor(CheckCircleIcon)

export const Checkbox = (props) => (
  <MuiCheckBox {...props} icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} />
);
