import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import PageContainer from '../../../shared/Container';
import Typography from '@mui/material/Typography';
import { authService } from '../../../../utils/auth';
import { uriStorage } from '../../../../utils/storage';
import { AnalyticsEvent, analyticsEventLogger } from '../../../../utils/events';
import { FormattedMarkdown } from '@decodedhealth/react-library';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  wrapper: {},
  image: {
    maxWidth: '100%',
    display: 'block',
  },
  text: {
    marginTop: '24px',
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '4.2em',
    lineHeight: '1',
    paddingBottom: '3em',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0em',
    },
  },
});

class PostEncounter extends Component {
  componentDidMount() {
    setTimeout(() => {
      uriStorage.clearPath();
      authService.logout();
    });

    analyticsEventLogger.log(AnalyticsEvent.CLINIC_POST_ENCOUNTER_OPEN);
  }

  render() {
    const { classes } = this.props;

    return (
      <PageContainer isEnd={true}>
        <div className={classes.root}>
          <span className={classes.wrapper}>
            <Typography variant="body1" className={classes.text}>
              <FormattedMarkdown id="thankyou" defaultMessage={`Thank You!`} />
            </Typography>
          </span>
        </div>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(PostEncounter);
