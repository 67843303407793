import { DecodedBloc } from '../../shared/DecodedComponent/bloc';
import { notificationService } from '../../../utils/notification';
import { QUINN_SCHEDULED, routeUtil } from '../../../utils/route.name';
import { AnalyticsEvent } from '../../../utils/events';

export class Bloc extends DecodedBloc {
  constructor(options) {
    super({ ...options, step: 0, frontImageValue: {}, backImageValue: {} });
    this.accountApi = options.accountApi;
  }

  initialise = () => {
    this.accountApi
      .listIdentityDocuments()
      .then(
        (value) => {
          const size = value.data.page.size > 0;
          this.logAnalyticsEvent(AnalyticsEvent.IDENTITY_CARD, { count: size });
          this.__updateSubject({ hasIdentityCards: size > 0 });
        },
        (reason) => {
          this.logAnalyticsEvent(AnalyticsEvent.IDENTITY_CARD_ERROR, { count: 0 });
          this.__updateSubject({ hasIdentityCards: false });
        },
      )
      .finally(() => this.__makeInitialised());
  };

  setImage = (part, value) => {
    let props = {};
    props[part] = value;
    this.__updateSubject({ ...props });
  };

  back = () => {
    const { step } = this.subject.value;
    this.__updateSubject({
      step: step - 1,
    });
  };

  next = () => {
    const { step } = this.subject.value;
    this.__updateSubject({
      step: step + 1,
    });
  };

  skip = () => {
    const { appointmentId } = this.subject.value;
    this.__publishEvent(BlocEvent.NAVIGATE_TO, {
      url: routeUtil.buildBookingPaymentMethodRouteWithDraftAppointmentID(
        appointmentId,
        QUINN_SCHEDULED,
      ),
    });
    this.logAnalyticsEvent(AnalyticsEvent.IDENTITY_CARD, { action: 'skip' });
  };

  handleSubmit = () => {
    const { appointmentId } = this.subject.value;
    // api call
    this.__updateSubject({ loading: true });
    const formData = new FormData();
    formData.append('front', this.subject.value.frontImageValue.imageBlob);
    formData.append('back', this.subject.value.backImageValue.imageBlob);
    this.accountApi
      .analyzeDocument(formData)
      .then(
        (r) => {
          return this.accountApi
            .command({
              command: 'set_identity_document',
              type: { system: 'decoded', code: 'verificationId' },
              reference: r.data.ref,
            })
            .finally(() => {
              this.__publishEvent(BlocEvent.NAVIGATE_TO, {
                url: routeUtil.buildBookingPaymentMethodRouteWithDraftAppointmentID(
                  appointmentId,
                  QUINN_SCHEDULED,
                ),
              });
              this.logAnalyticsEvent(AnalyticsEvent.IDENTITY_CARD, { action: 'uploaded' });
            });
        },
        (reason) => {
          notificationService.error(
            'Error uploading your identity card. Please try again. If the problem continues please contact the clinic.',
          );
          this.logAnalyticsEvent(AnalyticsEvent.IDENTITY_CARD_ERROR, {
            action: 'upload',
            error: reason,
          });
        },
      )
      .finally(() => {
        this.__updateSubject({ loading: false });
      });
  };
}

export class BlocEvent {
  static INITIALISED = 'INITIALISED';
  static NAVIGATE_TO = 'NAVIGATE_TO';
}
