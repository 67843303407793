import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import { Redirect } from 'react-router-dom';

import { authService } from '../../utils/auth';

import Loading from '../shared/Loading';
import { linksApi } from '../../utils/services/links.api';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';
import { notificationService } from '../../utils/notification';

import {
  BOOKING_PAYMENT_METHODS_ROUTE,
  LOGIN_ROUTE,
  QUINN_SCHEDULED,
  routeUtil,
  TELEHEALTH_LINK_VIRTUAL_CLINIC_WAITING_ROOM_ROUTE,
} from '../../utils/route.name';
import { uriStorage } from '../../utils/storage';
import { ErrorMessage } from '../../utils/error.resolver';
import { appointmentApi } from '../../utils/services/appointments.api';

const styles = (theme) => ({
  root: {
    overflow: 'auto',
    overscrollBehavior: 'contain',
    backgroundColor: '#313941',
    position: 'absolute',
    minHeight: '100%',
    height: '100%',
    width: '100%',
    color: '#fff',
  },
});

class ScheduleLink extends Component {
  constructor(props) {
    super(props);

    const {
      match: { params },
    } = this.props;

    this.state = {
      loading: true,
      message: 'Processing link.',
      appointmentId: params.appointmentId,
    };

    this._initialise(params);
  }

  _initialise = (params) => {
    analyticsEventLogger.log(AnalyticsEvent.LINK_OPEN, { appointmentId: params.appointmentId });

    linksApi
      .authenticateLink('schedule.link.authentication', params)
      .then((value) => this._authenticateUser(params, value))
      .catch((reason) => {
        notificationService.error(
          `The appointment schedule link is invalid. ${ErrorMessage.CALL_SUPPORT} You will be redirected to the login page shortly.`,
        );

        analyticsEventLogger.log(AnalyticsEvent.LINK_ERROR, {
          appointmentId: params.appointmentId,
          reason: `${reason}`,
        });

        this.setState({
          loading: false,
          error: reason,
        });

        setTimeout(() => {
          uriStorage.clearPath();
          authService.logout();
          window.location = LOGIN_ROUTE;
        }, 5000);
      });
  };

  _authenticateUser = (params, value) => {
    const appointmentId = params.appointmentId;

    analyticsEventLogger.log(AnalyticsEvent.LINK_VALID, { appointmentId: params.appointmentId });
    this.setState({
      message: 'Almost done.',
    });

    authService
      .loginWithToken(value.data.token)
      .then((user) => {
        analyticsEventLogger.setUser(user.uid);

        user
          .getIdToken()
          .then((token) => {
            analyticsEventLogger.log(AnalyticsEvent.LINK_AUTHENTICATED, {
              appointmentId: appointmentId,
            });

            this.setState({
              message: 'Going to scheduling workflow.',
            });

            this._loadAppointment(token, appointmentId);
          })
          .catch((reason) => this._authenticationError(appointmentId, reason));
      })
      .catch((reason) => this._authenticationError(appointmentId, reason));
  };

  _authenticationError = (reason) => {
    notificationService.error(
      `Error occurred during authentication. Please refresh and try again. If the problem persists please contact support. Cause '${reason}'`,
      AnalyticsEvent.LINK_ERROR,
      {
        reason: `${reason}`,
      },
    );

    this.setState({
      loading: false,
      error: reason,
    });
  };

  _loadAppointment = (token, appointmentId) => {
    appointmentApi
      .getAppointmentStatus(appointmentId)
      .then((data) => {
        analyticsEventLogger.log(AnalyticsEvent.LINK_SUCCESS, { appointmentId: appointmentId });

        const link = routeUtil.buildBookingRouteWithDraftAppointmentID(
          appointmentId,
          QUINN_SCHEDULED,
        );

        uriStorage.setCurrentPath(link);

        this.setState({
          appointment: data.data,
          link: link,
        });
      })
      .catch((reason) => {
        notificationService.error(
          `Error loading appointment summary. Please refresh and try again. If the problem persists please contact support. Cause '${reason}'`,
          AnalyticsEvent.LINK_ERROR,
          {
            reason: `${reason}`,
          },
        );

        this.setState({
          loading: false,
          error: reason,
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    let { classes } = this.props;

    let { loading, message, appointmentId, appointment, link } = this.state;

    return (
      <div className={classes.root}>
        {loading || !appointment ? (
          <Loading message={message} />
        ) : (
          <>
            <Redirect to={link} />
          </>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ScheduleLink);
