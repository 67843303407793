export class Logger {
  constructor() {
    this.level = process.env.REACT_APP_LOGGING_LEVEL || 'INFO';
  }

  debug = (...msg) => {
    if (['DEBUG'].includes(this.level)) console.log(...['debug:', ...msg]);
  };

  info = (...msg) => {
    if (['INFO', 'DEBUG'].includes(this.level)) console.log(...['info: ', ...msg]);
  };

  error = (...msg) => {
    console.error(...msg);
  };

  color = (...msg) => {
    console.log(...msg);
  };
}
export const logger = new Logger();
