import axios from 'axios';
import { VERSION } from '../version';
import { tenantUtil } from '../tenant';

class LinksApi {
  constructor() {
    this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || '';
    this.instance = axios.create();
    this.instance.interceptors.request.use(
      async (config) => {
        config.headers['X-DH-source'] = `Consumer Webapp`;
        config.headers['X-DH-version'] = VERSION;
        config.headers['X-API-KEY'] = process.env.REACT_APP_DH_API_KEY;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }

  authenticateLink(id, payload) {
    return this.instance
      .post(
        `${this.baseUrl}/api/accounts/me/auth/_custom`,
        { id: id, payload: payload },
        {
          headers: {
            Authorization: `Bearer ${tenantUtil.tenantToken()}`,
          },
        },
      )
      .then(function (res) {
        return res;
      })
      .catch(function (err) {
        return err;
      });
  }

  joinTelehealthSession(token, appointmentId) {
    return axios
      .get(`${this.baseUrl}/api/consumers/me/appointments/${appointmentId}/telehealth`)
      .then(function (res) {
        return res;
      })
      .catch(function (err) {
        return err;
      });
  }
}

export const linksApi = new LinksApi();
