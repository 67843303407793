import { MenuItem, styled } from '@mui/material';
import { SelectValidator } from 'react-material-ui-form-validator';

const Item = styled(MenuItem)({ '&:hover': { color: '#ffffff' } });

export const Select = ({
  value,
  onChange,
  id,
  name,
  select = true,
  fullWidth = true,
  label,
  options,
  getItemValue,
  getItemLabel,
  ...rest
}) => {
  return (
    <SelectValidator
      {...rest}
      id={id}
      name={name}
      select={select}
      fullWidth={fullWidth}
      label={label}
      value={value}
      onChange={onChange}
    >
      {options.map((val, i) => (
        <Item key={i} value={getItemValue?.(val) ?? val}>
          {getItemLabel?.(val) ?? val}
        </Item>
      ))}
    </SelectValidator>
  );
};
