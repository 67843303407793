import React from 'react';
import withStyles from '@mui/styles/withStyles';
import DecodedComponent from '../../../shared/DecodedComponent';
import InsuranceContext from '../context';
import {
  Card,
  CardContent,
  CardHeader,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import IconButton from '@mui/material/IconButton';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DecodedButton, { DecodedSecondaryButton } from '../../../common/DecodedButton';
import { DecodedDialog, DecodedDialogTitle } from '../../../shared/Dialog/Dialog';
import { TextStandard } from '../../../shared/Typography';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
  cardHeaderTitle: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '32px',
    color: '#000000',
  },

  cardHeaderTitleSubtitle: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '32px',
    color: '#777777',
  },
});

class InsuranceItem extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = props.context.bloc;
    this.state = {
      ...this.bloc.subject.value,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    const { insurances } = this.state;
    if (insurances.length === 0) {
      this.__newInsurance();
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  __openDialog = () => {
    this.setState({ open: true });
  };

  __closeDialog = () => {
    this.setState({ open: false });
  };

  __remove = () => {
    const { insurance } = this.props;
    this.bloc.removeInsurance(insurance);
    this.__closeDialog();
  };

  render() {
    const { insurance, classes, disabled } = this.props;
    const { open } = this.state;

    const actions = disabled ? (
      <></>
    ) : (
      <IconButton onClick={this.__openDialog} size={'large'} aria-label="delete">
        <DeleteOutlineIcon fontSize={'large'} scale={2} />
      </IconButton>
    );

    return (
      <>
        <Card variant="outlined" sx={{ border: '1px solid #F6F6F6', backgroundColor: '#F6F6F6' }}>
          <CardHeader
            classes={{
              title: classes.cardHeaderTitle,
              subheader: classes.cardHeaderTitleSubtitle,
            }}
            className={classes.cardHeader}
            action={actions}
            title={`${insurance.insuranceInformation.provider.name} ${insurance.insuranceInformation.plan.name} ${insurance.insuranceInformation.plan.type}`}
            subheader={insurance.insuranceInformation.memberId}
          ></CardHeader>
          <CardContent>
            <TextStandard>
              <FormattedMessage
                id={'insurance.information.policyholder'}
                defaultMessage={'Policy holder: '}
              ></FormattedMessage>{' '}
              {insurance.insuranceInformation.members.filter(
                (member) => member.role === 'POLICY_HOLDER',
              ).length === 0
                ? 'Yes'
                : 'No'}
            </TextStandard>
          </CardContent>
        </Card>
        <DecodedDialog
          open={open}
          onClose={this.__closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DecodedDialogTitle id="alert-dialog-title">{'Remove insurance'}</DecodedDialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to remove {insurance.insuranceInformation.plan.name}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <DecodedSecondaryButton onClick={this.__closeDialog}>No</DecodedSecondaryButton>
            <DecodedButton onClick={this.__remove} autoFocus>
              Yes
            </DecodedButton>
          </DialogActions>
        </DecodedDialog>
      </>
    );
  }
}

export default withStyles(styles)((props) => (
  <InsuranceContext.Consumer>
    {(value) => {
      return <InsuranceItem context={value} {...props} />;
    }}
  </InsuranceContext.Consumer>
));
