import { authService } from './auth';

const Cloudevent = require('cloudevents-sdk');

export const PING = 'customer.ping';

async function retrieveToken() {
  let token = await authService.getToken();
  if (!token) window.location.replace('/');
  return token;
}

export async function generatePingEvent() {
  let token = await retrieveToken();

  return new Cloudevent(Cloudevent.specs['0.2'])
    .source('/portal/dialog-management')
    .type(PING)
    .addExtension('metaExtension', {
      authorization: `Bearer ${token}`,
    })
    .data({});
}

export class AnalyticsEvent {
  static SYSTEM_PROPERTIES_LOADED_SUCCESS = 'system.properties.loaded.success';
  static SYSTEM_PROPERTIES_LOADED_ERROR = 'system.properties.loaded.error';

  static IDLE_SHOW = 'idle.show';

  static LINK_OPEN = 'link.open';
  static LINK_AUTHENTICATED = 'link.authenticated';
  static LINK_VALID = 'link.valid';
  static LINK_ERROR = 'link.error';
  static LINK_SUCCESS = 'link.success';

  static CALLBACK_INITIATED = 'callback.initiated';

  static LINK_CHECKIN_OPEN = 'link.checkin.open';
  static LINK_CHECKIN_AUTHENTICATED = 'link.checkin.authenticated';
  static LINK_CHECKIN_VALID = 'link.checkin.valid';
  static LINK_CHECKIN_ERROR = 'link.checkin.error';
  static CHECKIN_START = 'checkin.start';
  static CHECKIN_LOADED = 'checkin.loaded';
  static CHECKIN_SUCCESS = 'checkin.success';
  static CHECKIN_ERROR = 'checkin.error';
  static CHECKIN_ATTEMPT_COMPLETE = 'checkin.attempt.complete';

  static AUTH_OTP_REQUEST = 'auth.otp.request';
  static AUTH_OTP_SUCCESS = 'auth.otp.success';
  static AUTH_OTP_ERROR = 'auth.otp.error';

  static REGISTER_OPEN = 'registration.open';
  static REGISTER_SUCCESS = 'registration.success';
  static REGISTER_ERROR = 'registration.error';
  static REGISTER_SUBMIT_BUTTON_ERROR = 'registration.submit.button.error';
  static REGISTER_AUTH_ERROR = 'registration.auth.error';
  static REGISTER_PHONE_VERIFICATION_SUCCESS = 'registration.phone.verification.success';
  static REGISTER_PHONE_VERIFICATION_ERROR = 'registration.phone.verification.error';

  static PERSON_DETAILS_UPDATE_OPEN = 'person.details.update.open';
  static PERSON_DETAILS_RETRIEVAL_SUCCESS = 'person.details.retrieval.success';
  static PERSON_DETAILS_RETRIEVAL_ERROR = 'person.details.retrieval.error';
  static PERSON_DETAILS_UPDATE_SUCCESS = 'person.details.update.success';
  static PERSON_DETAILS_UPDATE_ERROR = 'person.details.update.error';

  static PHARMACY_RETRIEVAL_SUCCESS = 'pharmacy.retrieval.success';
  static PHARMACY_RETRIEVAL_ERROR = 'pharmacy.retrieval.error';
  static PHARMACY_UPDATE_SUCCESS = 'pharmacy.update.success';
  static PHARMACY_UPDATE_ERROR = 'pharmacy.update.error';

  static PAYMENT_METHOD_REVIEW_OPEN = 'payment.method.review.open';

  static PAYMENT_METHOD_UNSURE_LOADED = 'payment.method.unsure.loaded';
  static PAYMENT_METHOD_RETRIEVAL_SUCCESS = 'payment.method.retrieval.success';
  static PAYMENT_METHOD_RETRIEVAL_ERROR = 'payment.method.retrieval.error';

  static PAYMENT_METHOD_UPDATE_SUCCESS = 'payment.method.update.success';
  static PAYMENT_METHOD_UPDATE_ERROR = 'payment.method.update.error';
  static PAYMENT_METHOD_UPDATE_FAILED_DIRECT_BILL_SUCCESS =
    'payment.method.update.failed.directbill.success';
  static PAYMENT_METHOD_UPDATE_FAILED_DIRECT_BILL_ERROR =
    'payment.method.update.failed.directbill.error';

  static PAYMENT_METHOD_INSURANCE_CARD_UPDATE_SUCCESS =
    'payment.method.insurance.card.update.success';
  static PAYMENT_METHOD_INSURANCE_CARD_UPDATE_ERROR = 'payment.method.insurance.card.update.error';
  static PAYMENT_METHOD_INSURANCE_CARD_RETRIEVAL_SUCCESS =
    'payment.method.insurance.card.retrieval.success';
  static PAYMENT_METHOD_INSURANCE_CARD_RETRIEVAL_ERROR =
    'payment.method.insurance.card.retrieval.error';

  static QUINN_FEATURES = 'quinn.features';
  static QUINN_MEDICAL_ELICITATION = 'quinn.medical.elicitation';

  static QUINN_ROUTING = 'quinn.routing';
  static QUINN_ROUTING_ERROR = 'quinn.routing.error';

  static IDENTITY_CARD = 'customer.identity.card';

  static IDENTITY_CARD_ERROR = 'customer.identity.card.error';

  static TAKE_IMAGE = 'take.image';
  static TAKE_IMAGE_ERROR = 'take.image.error';

  static BOOKING_INPERSON_WALKIN_RESERVATION_OPEN = 'booking.inperson.walkin.reservation.open';
  static BOOKING_INPERSON_WALKIN_RESERVE_SUCCESS = 'booking.inperson.walkin.reservation.success';
  static BOOKING_INPERSON_WALKIN_RESERVE_ERROR = 'booking.inperson.walkin.reservation.error';

  static BOOKING_INPERSON_MAP_VIEW = 'booking.inperson.map.view';

  static BOOKING_APPOINTMENT_SCHEDULE_RESERVATION_OPEN = 'book.appt.schedule.reservation.open';
  static BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_SUCCESS = 'book.appt.schedule.retrieval.success';

  static BOOKING_APPOINTMENT_SCHEDULE_RETRIEVAL_ERROR = 'book.appt.schedule.retrieval.error';

  static BOOKING_APPOINTMENT_TYPE_SWITCH = 'book.appt.type.switch';
  static BOOKING_APPOINTMENT_SCHEDULE_PROVIDER_LOADED = 'book.appt.schedule.provider.loaded';
  static BOOKING_APPOINTMENT_SCHEDULE_PROVIDER_SELECT = 'book.appt.schedule.provider.select';
  static BOOKING_APPOINTMENT_SCHEDULE_TIME_LOADED = 'book.appt.schedule.time.loaded';
  static BOOKING_APPOINTMENT_SCHEDULE_TIME_SELECT = 'book.appt.schedule.time.select';
  static BOOKING_APPOINTMENT_SCHEDULE_REMINDER_SELECT = 'book.appt.schedule.reminder.select';
  static BOOKING_APPOINTMENT_SCHEDULE_DATE_LOADED = 'book.appt.schedule.date.loaded';
  static BOOKING_APPOINTMENT_SCHEDULE_DATE_SELECT = 'book.appt.schedule.date.select';
  static BOOKING_APPOINTMENT_SCHEDULE_CONFIRM_LOADED = 'book.appt.schedule.confirm.loaded';
  static BOOKING_APPOINTMENT_SCHEDULE_CONFIRM_SELECT = 'book.appt.schedule.confirm.select';

  static BOOKING_APPOINTMENT_PAYMENT_METHOD_LOADED = 'book.appt.payment.method.loaded';
  static BOOKING_APPOINTMENT_PAYMENT_METHOD_LOAD_ERROR = 'book.appt.payment.method.load.error';

  static BOOKING_APPOINTMENT_ORGANISATION_LOADED = 'book.appt.schedule.org.loaded';
  static BOOKING_APPOINTMENT_ORGANISATION_LOAD_ERROR = 'book.appt.schedule.org.load.error';
  static BOOKING_APPOINTMENT_BOOKING_APPOINTMENT_ORGANISATION_LOADED_SELECT =
    'book.appt.schedule.org.select';

  static BOOKING_INPERSON_SCHEDULE_RESERVATION_OPEN = 'booking.inperson.schedule.reservation.open';
  static BOOKING_INPERSON_SCHEDULE_RETRIEVAL_SUCCESS =
    'booking.inperson.schedule.retrieval.success';
  static BOOKING_INPERSON_SCHEDULE_RETRIEVAL_ERROR = 'booking.inperson.schedule.retrieval.error';
  static BOOKING_INPERSON_SCHEDULE_RESERVE_SUCCESS =
    'booking.inperson.schedule.reservation.success';
  static BOOKING_INPERSON_SCHEDULE_RESERVE_ERROR = 'booking.inperson.schedule.reservation.error';
  static BOOKING_APPOINTMENT_SCHEDULE_RESERVE_SUCCESS = 'book.appt.schedule.reservation.success';
  static BOOKING_APPOINTMENT_SCHEDULE_RESERVE_ERROR = 'book.appt.schedule.reservation.error';

  static BOOKING_VIRTUAL_SCHEDULE_RESERVATION_OPEN = 'booking.virtual.schedule.reservation.open';
  static BOOKING_VIRTUAL_SCHEDULE_RETRIEVAL_SUCCESS = 'booking.virtual.schedule.retrieval.success';
  static BOOKING_VIRTUAL_SCHEDULE_RETRIEVAL_ERROR = 'booking.virtual.schedule.retrieval.error';
  static BOOKING_VIRTUAL_SCHEDULE_RESERVE_SUCCESS = 'booking.virtual.schedule.reservation.success';
  static BOOKING_VIRTUAL_SCHEDULE_RESERVE_ERROR = 'booking.virtual.schedule.reservation.error';

  static BOOKING_VIRTUAL_WALK_IN_CONFIRM_SUCCESS = 'booking.virtual.walkin.confirmation.success';
  static BOOKING_VIRTUAL_WALK_IN_CONFIRM_ERROR = 'booking.virtual.walkin.confirmation.error';

  static BOOKING_IN_PERSON_WALK_IN_CONFIRM_SUCCESS = 'booking.inperson.walkin.confirmation.success';
  static BOOKING_IN_PERSON_WALK_IN_CONFIRM_ERROR = 'booking.inperson.walkin.confirmation.error';

  static BOOKING_IN_PERSON_SCHEDULE_CONFIRM_SUCCESS =
    'booking.inperson.schedule.confirmation.success';
  static BOOKING_IN_PERSON_SCHEDULE_CONFIRM_ERROR = 'booking.inperson.schedule.confirmation.error';

  static BOOKING_VIRTUAL_SCHEDULE_CONFIRM_SUCCESS = 'booking.virtual.schedule.confirmation.success';
  static BOOKING_VIRTUAL_SCHEDULE_CONFIRM_ERROR = 'booking.virtual.schedule.confirmation.error';

  static BOOKING_CANCELLATION_SUCCESS = 'booking.cancellation.success';
  static BOOKING_CANCELLATION_ERROR = 'booking.cancellation.error';

  static WAITTING_ROOM_PRECALL_OPEN = 'waitingRoom.precall.open';
  static WAITTING_ROOM_PRECALL_RETRIEVAL_SUCCESS = 'waitingRoom.precall.retrieval.success';
  static WAITTING_ROOM_PRECALL_RETRIEVAL_ERROR = 'waitingRoom.precall.retrieval.error';
  static WAITTING_ROOM_PRECALL_TEST_START = 'waitingRoom.precall.test.start';
  static WAITTING_ROOM_PRECALL_TEST_FINISHED = 'waitingRoom.precall.test.finished';
  static WAITTING_ROOM_PRECALL_TEST_VIDEO_FAILED = 'waitingRoom.precall.test.video.failed';
  static WAITTING_ROOM_PRECALL_TEST_AUDIO_FAILED = 'waitingRoom.precall.test.audio.failed';
  static WAITTING_ROOM_PRECALL_TEST_ERROR = 'waitingRoom.precall.test.error';
  static WAITTING_ROOM_TELEHEALTH_JOIN = 'waitingRoom.telehealth.join';

  static TELEHEALTH_START = 'telehealth.start';
  static TELEHEALTH_END = 'telehealth.end';
  static TELEHEALTH_ERROR = 'telehealth.error';

  static TELEHEALTH_DIALIN_START = 'telehealth.dialin.start';
  static TELEHEALTH_DIALIN_END = 'telehealth.dialin.end';

  static CLINIC_POST_ENCOUNTER_OPEN = 'postEncounter.open';

  static BOOKING_STATUS_RETRIEVAL_SUCCESS = 'booking.status.retrieval.success';
  static BOOKING_STATUS_RETRIEVAL_ERROR = 'booking.status.retrieval.error';

  static BOOKING_LIST = 'booking.list.open';
  static BOOKING_STATUS = 'booking.status.open';

  static LOGIN_FORM_INPUT_ERROR = 'login.form.input.error';
}

class AnalyticsEventLogger {
  initialise = (analytics) => {
    this._analytics = analytics;
  };

  setUser = (uid) => {
    this._analytics.setUserId(uid);
  };

  log = (event, data) => {
    this._analytics.logEvent(event, data);
  };
}

export const analyticsEventLogger = new AnalyticsEventLogger();
