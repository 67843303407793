class InsuranceStorage {
  constructor() {
    this.storageKey = 'insuranceCard';
  }

  setUpStorage = () => {
    if (!this.getInsuranceCardRef()) {
      sessionStorage.setItem(this.storageKey, '');
    }
  };

  getInsuranceCardRef = () => {
    return sessionStorage.getItem(this.storageKey);
  };

  setInsuranceCardRef = (value) => {
    this.setUpStorage();
    sessionStorage.setItem(this.storageKey, value);
  };

  isValueSet = () => {
    return this.getInsuranceCardRef() !== null && this.getInsuranceCardRef() !== '';
  };

  clearInsuranceCardRef = () => {
    sessionStorage.clear();
  };
}

export const insuranceStorage = new InsuranceStorage();
