import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import PageContainer from '../../common/PageContainer';
import {
  DefaultDisconnectedPageFooter,
  ScrollableQuinnContainer,
} from '../../common/ScrollableContainer';
import * as React from 'react';
import withStyles from '@mui/styles/withStyles';
import AccountContext from './context';
import { FormattedMessage } from 'react-intl';
import DecodedComponent from '../../shared/DecodedComponent';
import Loading from '../../shared/Loading';
import { notificationService } from '../../../utils/notification';
import DecodedButton from '../../common/DecodedButton';
import { H1TextTitle, H2TextTitleContent } from '../../shared/Typography';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

const DecodedRadio = styled(Radio, { color: 'secondary' })(({ theme }) => ({
  color: theme.palette.secondary.main,
  '& .MuiSvgIcon-root': {
    fontSize: 28,
  },
  '&.Mui-checked': {
    color: theme.palette.secondary.main,
  },
}));

const RadioFormControlLabel = styled(
  FormControlLabel,
  {},
)(({ theme }) => ({
  margin: '12px 0 12px 0',
  width: '100%',
  '& .MuiFormControlLabel-label': {
    width: '100%',
  },
}));

const RadioLabel = styled(H1TextTitle, { variant: 'h1' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  width: '100%',
}));
const RadioLabelSubtext = styled(Typography, { variant: 'p' })(({ theme }) => ({
  margin: '0 0 0 8px',
}));

class Balance extends DecodedComponent {
  constructor(props) {
    super(props);
    this.bloc = props.context.bloc;
    this.state = {
      initialised: false,
      selection: undefined,
    };
  }

  __amountChanged = (event) => {
    this.setState({
      amount: event.target.value || 0,
    });
  };

  __selectionChange = (event) => {
    if (event.target.value !== 'other') {
      this.setState({
        amount: 0,
        selection: event.target.value,
      });
    } else {
      this.setState({
        selection: event.target.value,
      });
    }
  };

  handleSubmit = async (event) => {
    const { amount, balance, minimum, initialised, selection } = this.state;
    if (balance === minimum) {
      this.bloc.setAccountPaymentAmount(minimum);
      this.bloc.next();
      return;
    }

    if (balance > minimum) {
      if (!selection) {
        notificationService.error('Please select an option.');
        return;
      }

      if (selection === 'other') {
        if (amount === undefined || amount === '') {
          notificationService.error('Please ensure that you at least enter the minimum amount.');
          return;
        }

        const actual = Number(amount);
        if (actual < minimum) {
          notificationService.error('Please ensure that you at least enter the minimum amount.');
          return;
        }
        if (actual > balance) {
          notificationService.error('The maximum is the balance of your account.');
          return;
        }

        this.bloc.setAccountPaymentAmount(actual);
      } else {
        this.bloc.setAccountPaymentAmount(Number(selection));
      }
      this.bloc.next();
      return;
    }

    this.bloc.setAccountPaymentAmount(0);
    this.bloc.next();
    return;
  };

  render() {
    const { amount, balance, minimum, initialised, selection } = this.state;

    if (initialised) {
      if (balance === minimum) {
        return this.__renderBalanceUnderMinimum();
      }
      if (balance > minimum) {
        return this.__render();
      }
    }

    return (
      <PageContainer
        loading={false}
        onBack={() => {
          this.bloc.back();
        }}
      >
        <ScrollableQuinnContainer
          messageId="payment.account.balance.loading.bubble"
          message={`Looking you your account balance`}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Loading isFullscreen={false} />
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
        <DefaultDisconnectedPageFooter justifyContent={'left'}>
          <Grid item xs={12} sm={6}>
            <Button variant="contained" color="primary" onClick={(e) => this.handleSubmit(e)}>
              <FormattedMessage
                id="payment.account.balance.button.continue"
                defaultMessage={'Continue'}
              />
            </Button>
          </Grid>
        </DefaultDisconnectedPageFooter>
      </PageContainer>
    );
  }

  __renderBalanceUnderMinimum = () => {
    const { amount, balance, minimum, initialised } = this.state;

    return (
      <PageContainer
        loading={false}
        onBack={() => {
          this.bloc.back();
        }}
      >
        <ScrollableQuinnContainer
          messageId="payment.account.balance.minimum.bubble"
          message={`Our records show that you have a due balance of $${balance.toFixed(2)}.`}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <H2TextTitleContent>
                <FormattedMessage
                  id={'payment.account.balance.minimum.heading'}
                  defaultMessage={
                    'You need to pay this due balance, otherwise we will not be able to see you today.'
                  }
                ></FormattedMessage>
              </H2TextTitleContent>
            </Grid>
            <Grid item xs={12}>
              <H2TextTitleContent>
                <FormattedMessage
                  id={'payment.account.balance.minimum.amountdue'}
                  defaultMessage={'Balance due today'}
                ></FormattedMessage>
              </H2TextTitleContent>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '40px !important',
                  lineHeight: '32px !important',
                  color: '#002F59',
                }}
              >
                ${amount?.toFixed(2)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ color: '#777777' }}>
                <FormattedMessage
                  id={'payment.account.balance.standard.subtext'}
                  defaultMessage={
                    '* This amount will be added to any visit cost amount that needs to be paid today.'
                  }
                ></FormattedMessage>
              </Typography>
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
        <DefaultDisconnectedPageFooter justifyContent={'left'}>
          <Grid item xs={12} sm={6}>
            <Button variant="contained" color="primary" onClick={(e) => this.handleSubmit(e)}>
              <FormattedMessage
                id="payment.account.balance.button.continue"
                defaultMessage={'Continue'}
              />
            </Button>
          </Grid>
        </DefaultDisconnectedPageFooter>
      </PageContainer>
    );
  };

  __render = () => {
    const { amount, balance, minimum, initialised, selection } = this.state;

    return (
      <PageContainer
        loading={false}
        onBack={() => {
          this.bloc.back();
        }}
      >
        <ScrollableQuinnContainer
          messageId="payment.account.balance.standard.bubble"
          message={`Our records show that you have a due balance of $${balance.toFixed(2)}.`}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <H2TextTitleContent>
                <FormattedMessage
                  id={'payment.account.balance.standard.text1'}
                  defaultMessage={
                    'You need to pay this due balance, otherwise we will not be able to see you today.'
                  }
                ></FormattedMessage>
              </H2TextTitleContent>
            </Grid>
            <Grid item xs={12}>
              <H2TextTitleContent>
                <FormattedMessage
                  id={'payment.account.balance.standard.text2'}
                  defaultMessage={
                    'Select the amount you want to pay out of your due balance today:'
                  }
                ></FormattedMessage>
              </H2TextTitleContent>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  value={selection}
                  onChange={this.__selectionChange}
                  sx={(theme) => ({
                    width: '100%',
                    display: 'block',
                  })}
                >
                  <RadioFormControlLabel
                    value={balance}
                    control={<DecodedRadio />}
                    label={
                      <RadioLabel>
                        {`$${balance.toFixed(2)}`}{' '}
                        <RadioLabelSubtext>(the total amount)</RadioLabelSubtext>
                      </RadioLabel>
                    }
                  />
                  <RadioFormControlLabel
                    value={minimum}
                    control={<DecodedRadio />}
                    label={
                      <RadioLabel>
                        {`$${minimum.toFixed(2)}`}{' '}
                        <RadioLabelSubtext>(the minimum amount)</RadioLabelSubtext>
                      </RadioLabel>
                    }
                  />
                  <Typography>OR</Typography>
                  <RadioFormControlLabel
                    value="other"
                    control={<DecodedRadio />}
                    label={
                      <TextField
                        min={minimum}
                        max={balance}
                        sx={{ width: '100%', ['& .MuiInput-root']: { fontSize: '18px' } }}
                        disabled={selection !== 'other'}
                        placeholder={`(enter your amount. Minimum is ${minimum})`}
                        value={selection === 'other' ? amount : ''}
                        type={'number'}
                        onChange={this.__amountChanged}
                      />
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ color: '#777777' }}>
                <FormattedMessage
                  id={'payment.account.balance.standard.subtext'}
                  defaultMessage={
                    '* This amount will be added to any visit cost amount that needs to be paid today.'
                  }
                ></FormattedMessage>
              </Typography>
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
        <DefaultDisconnectedPageFooter justifyContent={'left'}>
          <Grid item xs={12} sm={6}>
            <DecodedButton onClick={(e) => this.handleSubmit(e)}>
              <FormattedMessage
                id="payment.account.balance.button.continue"
                defaultMessage={'Continue'}
              />
            </DecodedButton>
          </Grid>
        </DefaultDisconnectedPageFooter>
      </PageContainer>
    );
  };
}

export default withStyles(styles)((props) => (
  <AccountContext.Consumer>
    {(value) => {
      return <Balance context={value} {...props} />;
    }}
  </AccountContext.Consumer>
));
