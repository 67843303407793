import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import PaymentMethod from './PaymentMethod';
import { ACCOUNT_PAYMENT_DETAILS_ROUTE } from '../../../utils/route.name';
import { PrivateRoute } from '../../../utils/protected.route';

const styles = (theme) => ({
  grid: {
    flex: '1',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  button: {
    width: '100%',
    padding: '3em',
    textAlign: 'center',
  },
});

class Account extends Component {
  render() {
    return (
      <React.Fragment>
        <PrivateRoute path={ACCOUNT_PAYMENT_DETAILS_ROUTE} component={PaymentMethod} />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Account);
