import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import DecodedComponent from '../../shared/DecodedComponent';
import { withRouter } from 'react-router-dom';
import ChatBubble from '../../shared/ChatBubble';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Loading } from '../../../shared-library';
import { FormattedMessage } from 'react-intl';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { H2TextTitleContent } from '../../shared/Typography';
import PageContainer from '../../common/PageContainer';

import {
  DefaultDisconnectedPageFooter,
  ScrollableQuinnContainer,
} from '../../common/ScrollableContainer';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

class Notice extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = props.bloc;
    this.state = {
      ...this.bloc.subject.value,
    };
  }

  render() {
    return (
      <PageContainer loading={false} hideBack={true}>
        <ScrollableQuinnContainer
          messageId={`paymentmethod.notice.loading.bubble`}
          message="Redirecting you to medicbuddy."
        >
          <Grid container direction="column" justifyContent="space-between" flex="1">
            <Loading isFullscreen={false} />
          </Grid>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(Notice);
