import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { notificationService } from '../../../../utils/notification';
import {
  APPOINTMENT_SCHEDULE,
  IN_PERSON_SCHEDULE,
  IN_PERSON_WALK_IN,
  routeUtil,
  VIRTUAL_SCHEDULE,
  VIRTUAL_WALK_IN,
} from '../../../../utils/route.name';
import { appointmentApi } from '../../../../utils/services/appointments.api';
import ChatBubble from '../../../shared/ChatBubble';
import { paymentApi } from '../../../../utils/services/payment.api';
import { AnalyticsEvent, analyticsEventLogger } from '../../../../utils/events';
import { Checkbox, CircularProgress, FormControlLabel } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { errorResolver } from '../../../../utils/error.resolver';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Link from '@mui/material/Link';
import PageContainer from '../../../shared/Container';
import { FormattedMarkdown } from '@decodedhealth/react-library';

const styles = (theme) => ({
  grid: {
    flex: '1',
    paddingTop: '2em',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textItem: {
    flex: '1',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  amount: {
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '5.6em',
    lineHeight: '1.214',
    color: '#0F0F0F',
    marginBottom: '0.44643em',
  },
  confirmation: {
    width: '80%',
    lineHeight: '1.75',
    color: '#575757',
    marginBottom: '1.875em',
    paddingTop: '0.5em',
    padding: '3em',
    [theme.breakpoints.down('sm')]: {
      padding: '0em',
    },
  },
  button: {
    width: '100%',
    padding: '1em',
    textAlign: 'right',
  },
  progress: {
    color: '#F29202',
  },
  documents: {
    paddingTop: '14px',
    paddingBottom: '14px',
    lineHeight: '14px',
  },
});

class PaymentConfirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isInsured: false,
      error: false,
    };
  }

  componentDidMount() {
    let { setLoading, appointmentId } = this.props;

    setLoading(true);
    appointmentApi.getAppointmentStatus(appointmentId).then(
      (value) => {
        this.setState({
          appointment: value.data,
        });
        this.__loadPaymentMethod(setLoading);
      },
      (reason) => {
        console.log(reason);
        this.setState({
          error: true,
        });

        notificationService.error(errorResolver.resolvePaymentDetailsRetrievalError(reason));
        setLoading(false);
      },
    );
  }

  __loadPaymentMethod = (setLoading) => {
    paymentApi
      .getPaymentMethods()
      .then((response) => {
        this.setState({
          isInsured: !!response.data.items[0].insuranceInformation,
        });
      })
      .catch((error) => {
        this.setState({
          error: true,
        });

        notificationService.error(errorResolver.resolvePaymentDetailsRetrievalError(error));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  _handleBack = () => {
    let { history } = this.props;

    // history.replace(routeUtil.buildBookingRouteWithDraftAppointmentID(appointmentId, appointmentType));
    history.goBack();
  };

  handleButtonClick = () => {
    let { history, appointmentId, appointmentType, setLoading } = this.props;
    let { appointment, consented } = this.state;

    switch (appointmentType) {
      case VIRTUAL_SCHEDULE:
        setLoading(true);
        appointmentApi
          .checkInAppointment(appointmentId)
          .then((response) => {
            analyticsEventLogger.log(AnalyticsEvent.BOOKING_VIRTUAL_SCHEDULE_CONFIRM_SUCCESS, {
              appointmentId: appointmentId,
            });

            history.push(
              routeUtil.buildPostBookingConfirmationRouteWithAppointmentID(
                appointmentId,
                appointmentType,
              ),
            );
          })
          .catch((error) => {
            analyticsEventLogger.log(AnalyticsEvent.BOOKING_VIRTUAL_SCHEDULE_CONFIRM_ERROR, {
              appointmentId: appointmentId,
              reason: error,
            });

            notificationService.error(errorResolver.resolveBookingConfirmationError(error));
          })
          .finally(() => {
            setLoading(false);
          });
        break;
      case VIRTUAL_WALK_IN:
        setLoading(true);
        appointmentApi
          .checkInAppointment(appointmentId)
          .then((response) => {
            analyticsEventLogger.log(AnalyticsEvent.BOOKING_VIRTUAL_WALK_IN_CONFIRM_SUCCESS, {
              appointmentId: appointmentId,
            });

            history.push(routeUtil.buildVirtualWaitingRoomRouteWithAppointmentID(appointmentId));
          })
          .catch((error) => {
            analyticsEventLogger.log(AnalyticsEvent.BOOKING_VIRTUAL_WALK_IN_CONFIRM_ERROR, {
              appointmentId: appointmentId,
              reason: error,
            });

            notificationService.error(errorResolver.resolveBookingConfirmationError(error));
          })
          .finally(() => {
            setLoading(false);
          });
        break;
      case APPOINTMENT_SCHEDULE:
      case IN_PERSON_SCHEDULE:
        setLoading(true);

        let data = {};
        if (appointment.service.includes('_MDS')) {
          data.consents = [
            { system: 'decoded/consent', code: 'cosmeticTerms', value: `${consented}` },
          ];
        }

        appointmentApi
          .confirmAppointment(appointmentId, data)
          .then((response) => {
            analyticsEventLogger.log(AnalyticsEvent.BOOKING_IN_PERSON_SCHEDULE_CONFIRM_SUCCESS, {
              appointmentId: appointmentId,
            });

            history.push(
              routeUtil.buildPostBookingConfirmationRouteWithAppointmentID(
                appointmentId,
                appointmentType,
              ),
            );
          })
          .catch((error) => {
            analyticsEventLogger.log(AnalyticsEvent.BOOKING_IN_PERSON_SCHEDULE_CONFIRM_ERROR, {
              appointmentId: appointmentId,
              reason: error,
            });

            notificationService.error(errorResolver.resolveBookingConfirmationError(error));
          })
          .finally(() => {
            setLoading(false);
          });
        break;
      case IN_PERSON_WALK_IN:
        setLoading(true);
        appointmentApi
          .confirmAppointment(appointmentId)
          .then((response) => {
            analyticsEventLogger.log(AnalyticsEvent.BOOKING_IN_PERSON_WALK_IN_CONFIRM_SUCCESS, {
              appointmentId: appointmentId,
            });

            history.replace(routeUtil.buildBookingStatusRouteWithAppointmentID(appointmentId));
          })
          .catch((error) => {
            analyticsEventLogger.log(AnalyticsEvent.BOOKING_IN_PERSON_WALK_IN_CONFIRM_ERROR, {
              appointmentId: appointmentId,
              reason: error,
            });

            notificationService.error(errorResolver.resolveBookingConfirmationError(error));
          })
          .finally(() => {
            setLoading(false);
          });
        break;
      default:
        throw new Error('Unsupported flow!');
    }
  };

  renderBubbleMessage(error) {
    let { appointmentType } = this.props;

    let assistantMessage = error
      ? {
          id: 'payment.confirmation.bubble.error',
          text: 'We cannot verify your payment details. Please try again.',
        }
      : {
          id: 'payment.confirmation.bubble.success',
          text: 'Thank you. Before we continue, please accept the below statement and we will connect you with a provider right away.',
        };

    if (appointmentType === APPOINTMENT_SCHEDULE) {
      assistantMessage = error
        ? {
            id: 'payment.appointment.confirmation.bubble.error',
            text: 'We cannot verify your payment details. Please try again.',
          }
        : {
            id: 'payment.appointment.confirmation.bubble.success',
            text: 'Thank you. Before we continue, please accept the below statement and we will connect you with a provider right away.',
          };
    }

    return assistantMessage;
  }

  renderButtonText(error) {
    let { appointmentType } = this.props;

    let buttonText = {
      id: 'payment.confirmation.button.text',
      text: 'Accept and see the provider',
    };

    if (appointmentType === APPOINTMENT_SCHEDULE) {
      buttonText = {
        id: 'payment.appointment.confirmation.button.text',
        text: 'Accept',
      };
    }

    return buttonText;
  }

  renderMessage() {
    let { error, isInsured } = this.state;
    let { appointmentType } = this.props;

    if (error) {
      return '';
    }

    if (isInsured) {
      if (appointmentType === APPOINTMENT_SCHEDULE) {
        return (
          <FormattedMarkdown
            id="payment.confirmation.insurance.appointment"
            defaultMessage={
              'Insurance is not accepted for this service, you will be responsible for the cost of the visit.'
            }
          />
        );
      }

      return (
        <FormattedMarkdown
          id="payment.confirmation.insurance"
          defaultMessage="We will bill your insurance with the information provided. However, if your insurer does not cover the cost of your visit, you will be responsible for the cost of the visit."
        />
      );
    }

    const defaultText =
      'As no insurance information was provided, you will be responsible for the cost of the visit.';
    switch (appointmentType) {
      case VIRTUAL_SCHEDULE:
      case VIRTUAL_WALK_IN:
        return (
          <FormattedMarkdown
            id="payment.confirmation.direct.virtual"
            defaultMessage={defaultText}
          />
        );
      case IN_PERSON_WALK_IN:
      case IN_PERSON_SCHEDULE:
        return (
          <FormattedMarkdown
            id="payment.confirmation.direct.inperson"
            defaultMessage={defaultText}
          />
        );
      case APPOINTMENT_SCHEDULE:
        return (
          <FormattedMarkdown
            id="payment.confirmation.selfpay.appointment"
            defaultMessage={
              'Insurance is not accepted for this service, you will be responsible for the cost of the visit.'
            }
          />
        );
      default:
        break;
    }
  }

  render() {
    let { error } = this.state;

    const { classes, loading } = this.props;

    const assistantMessage = this.renderBubbleMessage(error);

    return (
      <PageContainer loading={loading} handleBack={this._handleBack}>
        <div>
          <ChatBubble messageId={assistantMessage.id} message={assistantMessage.text} />
        </div>

        <div className={classes.grid}>
          <div className={classes.textItem}>
            <div className={classes.confirmation}>
              <Typography variant="body2" component={'div'}>
                {loading ? (
                  <CircularProgress classes={{ circle: classes.progress }} />
                ) : (
                  this.renderMessage()
                )}
              </Typography>
            </div>
          </div>

          {this.__renderActions()}
        </div>
      </PageContainer>
    );
  }

  handleCheckboxChange = (event) => {
    let change = {};
    change[event.target.name] = event.target.checked;
    this.setState(change);
  };

  __renderActions() {
    const { appointment, error } = this.state;
    const { loading, classes } = this.props;
    const buttonText = this.renderButtonText(error);

    console.log(appointment);

    if (!appointment) return <></>;

    if (appointment.service.includes('_MDS')) {
      const { consented } = this.state;

      return (
        <>
          <ValidatorForm
            autoComplete="off"
            onSubmit={this.handleButtonClick}
            className={classes.form}
          >
            <FormControlLabel
              style={{ fontSize: '1.2em', textAlign: 'left' }}
              control={
                <Checkbox
                  required
                  checked={consented}
                  onChange={this.handleCheckboxChange}
                  name="consented"
                  color="primary"
                />
              }
              label={
                <FormattedMarkdown
                  id="confirmation.medspa.text"
                  defaultMessage="I have read the below instructions and consent and agree to follow the preparation instructions prior to my visit."
                />
              }
            />

            <div className={classes.documents}>
              <Link
                rel="noopener noreferrer"
                target="_blank"
                href={
                  'https://ondutyurgentcare.com/wp-content/uploads/2021/06/Botulinum-Toxin-Treatment-Instructions-and-Consent.pdf'
                }
                underline="hover"
              >
                <Typography variant="body2" component={'div'}>
                  <FormattedMarkdown
                    id="confirmation.cosmetic.botox.consent.link"
                    defaultMessage="Botulinum Toxin treatment instructions and consent"
                  />
                </Typography>
              </Link>

              <br />

              <Link
                rel="noopener noreferrer"
                target="_blank"
                href={
                  'https://ondutyurgentcare.com/wp-content/uploads/2021/06/Dermal-Filler-Instructions-and-Consent.pdf'
                }
                underline="hover"
              >
                <Typography variant="body2" component={'div'}>
                  <FormattedMarkdown
                    id="confirmation.cosmetic.filler.consent.link"
                    defaultMessage="Dermal filler instructions and consent"
                  />
                </Typography>
              </Link>
            </div>

            <div className={classes.button}>
              <Button type="submit" variant="contained" color="primary" disabled={!consented}>
                <FormattedMessage id={buttonText.id} defaultMessage={buttonText.text} />
              </Button>
            </div>
          </ValidatorForm>
        </>
      );
    }
    return (
      <div className={classes.button}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading || error}
          onClick={this.handleButtonClick}
        >
          <FormattedMessage id={buttonText.id} defaultMessage={buttonText.text} />
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(PaymentConfirmation));
