import { Subject } from 'rxjs';

export class TelehealthEventService {
  subject = new Subject();

  registerStateCallback(callback) {
    return this.subject.subscribe(callback);
  }

  update(event) {
    this.subject.next(event);
  }
}

export const telehealthEventService = new TelehealthEventService();
