import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import DecodedComponent from '../../shared/DecodedComponent';
import { withRouter } from 'react-router-dom';

import AccountContext from './context';
import Loading from './Initialising';
import VisitCost from './VisitCost';
import Balance from './Balance';
import Initialising from './Initialising';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

class Account extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = props.bloc;
    this.state = { initialised: false };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    let context = { bloc: this.bloc };

    const component = this.__render();

    return (
      <AccountContext.Provider value={context}>
        <>{component}</>
      </AccountContext.Provider>
    );
  }

  __render() {
    const { initialised, step } = this.state;

    if (!initialised) {
      return <Initialising />;
    }

    switch (step) {
      case 0: {
        return <VisitCost />;
      }
      case 1: {
        return <Balance bloc={this.bloc} />;
      }
    }

    return <></>;
  }
}

export default withStyles(styles)(withRouter(Account));
