import React, {Component} from "react";
import imageCompression from "browser-image-compression";
import {H2TextTitleContent} from '../shared/Typography';
import withStyles from "@mui/styles/withStyles";
import TakeImage from "../shared/TakeImage";

const styles = (theme) => ({});


class FrontScan extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            image: props.imageValue,
        };
    }

    _imageCompressionOption = () => {
        const maxResolutionFlag = process.env.REACT_APP_IMAGE_COMPRESSION_MAX_RESOLUTION;
        const maxResolution = maxResolutionFlag.length > 0 ? parseInt(maxResolutionFlag) : 480;

        return {
            maxSizeMB: 1,
            maxWidthOrHeight: maxResolution,
            useWebWorker: true,
        };
    };

    __next = async (e) => {
        this.props.onImageSubmit();
    }

    __handleScanCapture = async ({dataURL}) => {

        const {onScanCapture, fileName} = this.props;

        this.setState({loading: true});

        const blob = await (await fetch(dataURL)).blob();

        const imageFile = new File([blob], fileName, {
            type: 'image/jpeg',
            lastModified: new Date(),
        });

        return imageCompression(imageFile, this._imageCompressionOption())
            .then((compressedFile) => {
                onScanCapture(compressedFile)
                this.setState((prevState) => ({
                    image: dataURL,
                }));
            })
            .catch((error) => {
                console.error(error.message);
            })
            .finally(() => {
                this.setState({loading: false});
            });
    };


    render() {

        const {
            onBack,
            heading,
        } = this.props;

        const {image, loading} = this.state;

        return (
            <>
                <TakeImage
                    title={
                        <H2TextTitleContent>
                            {heading}
                        </H2TextTitleContent>
                    }
                    initialImage={image}
                    handleScanCapture={this.__handleScanCapture}
                    handleBack={onBack}
                    onSubmit={this.__next}
                    loading={loading}
                />
            </>

        );
    }
}

export default withStyles(styles)(FrontScan);
