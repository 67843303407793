import React from 'react';
import { withRouter } from 'react-router-dom';
import PageContainer from '../../common/PageContainer';
import { uriStorage } from '../../../utils/storage';
import { ScrollableQuinnContainer } from '../../common/ScrollableContainer';
import DecodedComponent from '../../shared/DecodedComponent';
import { Bloc, BlocEvent } from './bloc';
import { IntroStep } from './IdentityVerification';
import FrontScan from '../../Registration/FrontScan';
import { FormattedMessage } from 'react-intl';
import BackScan from '../../Registration/BackScan';
import { accountApi } from '../../../utils/services/account.api';
import { analyticsEventLogger } from '../../../utils/events';

class IdentityDocument extends DecodedComponent {
  constructor(props) {
    super(props);
    this.state = { initialising: false };
    this.bloc = new Bloc({
      accountApi: accountApi,
      appointmentId: this._getCurrentAppointmentId(),
      analyticsLogger: analyticsEventLogger,
    });
  }

  componentDidMount() {
    super.componentDidMount();
    uriStorage.setCurrentPath(this.props.match.url);
    this.bloc.initialise();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.cancelBlocEventSubscription?.unsubscribe();
  }

  _getCurrentAppointmentId = () => {
    return this.props.match.params.appointmentId
      ? this.props.match.params.appointmentId
      : this.props.appointmentId;
  };

  __handleEvent = (event) => {
    const { type, data } = event;
    if (type === BlocEvent.NAVIGATE_TO) {
      this.props.history.push(data.url);
    }
  };

  handleNext = () => {
    this.bloc.next();
  };

  handleBack = () => {
    this.bloc.back();
  };

  handleFrontScanCapture = (file) => {
    this.bloc.setImage('frontImageValue', { image: URL.createObjectURL(file), imageBlob: file });
  };
  handleBackScanCapture = (file) => {
    this.bloc.setImage('backImageValue', { image: URL.createObjectURL(file), imageBlob: file });
  };

  skip = () => {
    this.bloc.skip();
  };

  handleBackImageSubmit = () => {
    this.bloc.handleSubmit();
  };

  render() {
    const { initialising } = this.state;

    if (initialising) {
      return (
        <PageContainer loading={true} hideBack={true}>
          <ScrollableQuinnContainer
            messageId={'identity.document.bubble.loading'}
            message={'Please wait while I check for your identity document on file.'}
          ></ScrollableQuinnContainer>
        </PageContainer>
      );
    }

    return this.renderStep();
  }

  renderStep = () => {
    const { initialising, step, isLoading } = this.state;

    switch (step) {
      case 1:
        return (
          <FrontScan
            loading={isLoading}
            onBack={this.handleBack}
            fileName={'front.jpg'}
            imageValue={this.state.frontImageValue.image}
            onScanCapture={this.handleFrontScanCapture}
            heading={
              <FormattedMessage
                id="registration.scan.document.front.heading"
                defaultMessage="Step 1: Front side of the card"
              />
            }
            onImageSubmit={this.handleNext}
            submitLabel={
              <FormattedMessage
                id="registration.scan.document.front.submit.label"
                defaultMessage="Submit"
              />
            }
          />
        );
      case 2:
        return (
          <BackScan
            loading={isLoading}
            onBack={this.handleBack}
            fileName={'back.jpg'}
            frontImageValue={this.state.frontImageValue.image}
            imageValue={this.state.backImageValue.image}
            onScanCapture={this.handleBackScanCapture}
            heading={
              <FormattedMessage
                id="registration.scan.document.back.heading"
                defaultMessage="Step 2: Back side of the card"
              />
            }
            onImageSubmit={this.handleBackImageSubmit}
            submitLabel={
              <FormattedMessage
                id="registration.scan.document.back.submit.label"
                defaultMessage="Submit"
              />
            }
          />
        );
      default: {
        const { hasIdentityCards } = this.state;
        return (
          <IntroStep existing={hasIdentityCards} onScanID={this.handleNext} onSkip={this.skip} />
        );
      }
    }
  };
}

export default withRouter(IdentityDocument);
