import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import Arrow from '../../../assets/icons/arrow-left.svg';
import HoverArrow from '../../../assets/icons/arrow-left_orange.svg';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const styles = (theme) => ({
  text: {
    fontWeight: '700',
    fontSize: '1.8em',
    lineHeight: '1.222',
    textAlign: 'center',
    color: '#525252',
    display: 'inline-block',
    cursor: 'pointer',
    padding: '0 0 0 15px',
    textDecoration: 'none',
    background: 'url(' + Arrow + ') no-repeat left center',

    '&:hover': {
      textDecoration: 'none',
      color: '#F29202',
      backgroundImage: 'url(' + HoverArrow + ')',
    },
  },
  backArrow: {
    color: theme.palette.primary.main,
  },
});

class BackButton extends Component {
  render() {
    const { classes, handleBack, handleBackIcon } = this.props;

    return (
      <IconButton aria-label="back" onClick={handleBack} className={classes.backArrow}>
        {handleBackIcon ? handleBackIcon : <ArrowBackIcon fontSize="large" />}
      </IconButton>
    );
  }
}

export default withStyles(styles)(BackButton);
