import React, { Component } from 'react';

import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import withStyles from '@mui/styles/withStyles';
import { Snackbar } from '@mui/material';
import { notificationService } from '../../../utils/notification';
import CloseIcon from '@mui/icons-material/Close';
import { green, red } from '@mui/material/colors';

const styles = (theme) => ({
  root: {},
  snackbarSuccess: {
    backgroundColor: green[600],
    padding: theme.spacing(0.5),
  },
  snackbarError: {
    backgroundColor: red[600],
    padding: theme.spacing(0.5),
  },
  close: {
    padding: theme.spacing(0.5),
  },
});

class LocalNotifications extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleNotification = this.handleNotification.bind(this);
    notificationService.registerStateCallback(this.handleNotification);
  }

  componentWillUnmount() {
    if (this.queuePoller) {
      clearInterval(this.queuePoller);
    }
    if (this.restrictedQueuePoller) {
      clearInterval(this.restrictedQueuePoller);
    }
  }

  handleNotification = (notification) => {
    this.setState({
      ...notification,
    });
  };

  handleSuccessClose = (event, reason) => {
    this.setState({
      success: undefined,
    });
  };

  handleErrorClose = (event, reason) => {
    this.setState({
      error: undefined,
    });
  };

  render() {
    const { classes } = this.props;
    const { success, error } = this.state;

    let errorAlert = <></>;

    if (error) {
      errorAlert = (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={true}
          autoHideDuration={6000}
          message={<span id="message-id">{error}</span>}
          onClose={this.handleErrorClose}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleErrorClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      );
    }

    let successAlert = <></>;

    if (success) {
      successAlert = (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={true}
          autoHideDuration={6000}
          message={<span id="message-id">{success}</span>}
          onClose={this.handleSuccessClose}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleSuccessClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      );
    }

    return (
      <>
        {errorAlert}
        {successAlert}
      </>
    );
  }
}

LocalNotifications.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LocalNotifications);
