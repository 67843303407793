export class ConfigurationUtil {
  getEnableTimeZoneFlag = () => {
    const defaultValue = '1';
    const timezoneEnableFlag = process.env.REACT_APP_CLINIC_TIMEZONE_ENABLED;
    const clinicTimezoneEnabledValue =
      timezoneEnableFlag?.length > 0 ? timezoneEnableFlag : defaultValue;

    return parseInt(clinicTimezoneEnabledValue);
  };

  getEnableTelehealthChatFlag = () => {
    const defaultValue = '1';
    const telehealthChatFlag = process.env.REACT_APP_TELEHEALTH_CHAT_ENABLED;
    const telehealthChatFlagValue =
      telehealthChatFlag.length > 0 ? telehealthChatFlag : defaultValue;

    return parseInt(telehealthChatFlagValue);
  };

  getAppointmentWindowValue = () => {
    const defaultHours = '8';
    const appointmentWindow = process.env.REACT_APP_APPOINTMENT_CHECKIN_WINDOW;
    const appointmentWindowValue =
      appointmentWindow && appointmentWindow.length > 0 ? appointmentWindow : defaultHours;

    return parseInt(appointmentWindowValue);
  };
}

export const configurationUtil = new ConfigurationUtil();
