import * as rxjs from 'rxjs';
import { notificationService } from '../../utils/notification';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';
import React from 'react';

export class Bloc {
  constructor(props) {
    let extra = {};
    const loginDetails = JSON.parse(sessionStorage.getItem('loginDetails'));
    if (loginDetails) extra.loginDetails = loginDetails;

    this.subject = new rxjs.BehaviorSubject({
      initialising: true,
      ...props,
      ...extra,
    });

    this.events = new rxjs.Subject();

    this.__initialise();
  }

  __updateSubject = (value) =>
    this.subject.next({
      ...this.subject.value,
      ...value,
    });

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  __initialise = () => {
    this.__updateSubject({ initialising: false });
  };
}

export class BlocEvent {
  static INITIALISED = 'INITIALISED';
  static NAVIGATE_TO = 'NAVIGATE_TO';
}
