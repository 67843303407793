import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';

import callIcon from '../../assets/icons/call_error.svg';
import { telehealthEventService } from './service/telehealth.event.service';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';

const styles = (theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  fill: {
    flex: '1 1 auto',
  },
  wrapper: {
    margin: 'auto',
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '22px',
    lineHeight: '33px',
    textAlign: 'center',

    color: '#E16B1E',
  },
  callWrapper: {
    display: 'flex',
  },
  errorImage: {
    margin: 'auto',
  },
  button: {
    textAlign: 'center',
    padding: '2em',
  },
});

class Retry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      localMessage: this._resolveLocalErrorMessage(this.props.error),
      showRetry: this._resolveIfRetryShouldBeShown(this.props.error),
    };
  }

  _resolveLocalErrorMessage = (error) => {
    if (error.code === 1004) {
      return 'The telehealth token has expired. Please contact your provider.';
    }

    return 'An error occurred joining the session click to retry. If the problem persists please try refresh your browser or contact your provider.';
  };

  _resolveIfRetryShouldBeShown = (error) => {
    return error.code !== 1004;
  };

  _join = () => {
    telehealthEventService.update({
      type: 'ENCOUNTER_CALL_JOIN',
      data: {},
    });
  };

  render() {
    let { error, classes } = this.props;
    let { localMessage, showRetry } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <div className={classes.callWrapper}>
            <img className={classes.errorImage} src={callIcon} alt="telehealth error icon" />
            <br />
          </div>

          <div className={classes.text}>{localMessage}</div>
          <div className={classes.text}>Error: {error.message}</div>

          {showRetry && (
            <div className={classes.button}>
              <Button variant="contained" color="primary" onClick={this._call}>
                <FormattedMessage
                  id="teleheath.display.error.button.try"
                  defaultMessage={'Try again'}
                />
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Retry);
