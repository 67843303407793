import MaskedInput from 'react-text-mask';
import React from 'react';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

export const PhoneTextMask = React.forwardRef((props, inputRef) => {
  return (
    <MaskedInput
      {...props}
      ref={(ref) => {
        inputRef?.(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
});

export const ZipTextMask = React.forwardRef((props, inputRef) => {
  return (
    <MaskedInput
      {...props}
      ref={(ref) => {
        inputRef?.(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
});

export const SsnTextMask = React.forwardRef((props, inputRef) => {
  return (
    <MaskedInput
      {...props}
      ref={(ref) => {
        inputRef?.(ref ? ref.inputElement : null);
      }}
      mask={[/[1-8]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
});

export const PinCodeMask = React.forwardRef((props, inputRef) => {
  return (
    <MaskedInput
      {...props}
      ref={(ref) => {
        inputRef?.(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
});

export const DateMask = React.forwardRef((props, inputRef) => {
  const autoCorrectedDatePipe = createAutoCorrectedDatePipe('MM/dd/yyyy', { minYear: 1890 });

  return (
    <MaskedInput
      {...props}
      ref={(ref) => {
        inputRef?.(ref ? ref.inputElement : null);
      }}
      mask={[/[0-1]/, /\d/, '/', /[0-3]/, /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      keepCharPositions={true}
      pipe={autoCorrectedDatePipe}
    />
  );
});
