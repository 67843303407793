import { useHistory } from 'react-router';
import { useEffect } from 'react';
import { authService } from './auth';
import { uriStorage } from './storage';
import { LOGIN_ROUTE } from './route.name';

export const useLogoutBeforeUnload = (shouldLogout, hashChange) => {
  const history = useHistory();
  useEffect(() => {
    if (shouldLogout) {
      window.onbeforeunload = () => {
        authService.logout();
        uriStorage.clearPath();
        window.location = LOGIN_ROUTE;
      };
      if (hashChange) {
        history.listen(() => {
          if (history.action === 'POP') {
            authService.logout();
            uriStorage.clearPath();
            window.location = LOGIN_ROUTE;
          }
        });
      }
    }
    return () => {
      if (shouldLogout) {
        window.onbeforeunload = null;
      }
    };
  }, [hashChange, shouldLogout, history]);
};
