import * as rxjs from 'rxjs';
import { appointmentApi } from '../../../utils/services/appointments.api';
import { notificationService } from '../../../utils/notification';

export class ClinicBloc {
  constructor() {
    this.subject = new rxjs.BehaviorSubject({
      video: true,
      audio: true,
      sessionReturned: false,
      messageUnread: false,
      encounterId: '',
      dialinInfo: {
        name: '',
      },
    });

    this.events = new rxjs.Subject();
  }

  subscribeToEvents = (func) => this.events.subscribe(func);
  subscribeToState = (func) => this.subject.subscribe(func);

  toggleVideo = () => {
    const current = this.subject.value;

    this.subject.next({
      ...current,
      video: !current.video,
    });
  };

  toggleAudio = () => {
    const current = this.subject.value;

    this.subject.next({
      ...current,
      audio: !current.audio,
    });
  };

  setSessionReturnedStatus = (value) => {
    this._updateSubject({ sessionReturned: value });
  };

  setEncounterId = (value) => {
    this._updateSubject({ encounterId: value });
  };

  setMessageUnread = (value) => {
    this._updateSubject({ messageUnread: value });
  };

  setDialinInfo = (value) => {
    this._updateSubject({ dialinInfo: value });
  };

  _updateSubject = (newProps) => {
    const _current = this.subject.value;

    this.subject.next({
      ..._current,
      ...newProps,
    });
  };

  getChatHistory = () => {
    appointmentApi
      .getChatHistory(this.subject.value.encounterId)
      .then((response) => {
        this.events.next({
          type: ClinicBlocEvent.CHAT_HISTORY_LOAD_SUCCESS,
          data: {
            messages: response.data.items,
          },
        });
      })
      .catch((error) => {
        this.events.next({
          type: ClinicBlocEvent.CHAT_HISTORY_LOAD_ERROR,
          data: {
            error: error,
          },
        });

        notificationService.error('Unable to retrieve chat history.');
      });
  };
}

export class ClinicBlocEvent {
  static AUDIO_TOGGLED = 'AUDIO_TOGGLED';
  static VIDEO_TOGGLED = 'VIDEO_TOGGLED';
  static CHAT_HISTORY_LOAD_SUCCESS = 'CHAT_HISTORY_LOAD_SUCCESS';
  static CHAT_HISTORY_LOAD_ERROR = 'CHAT_HISTORY_LOAD_ERROR';
}

export const clinicBloc = new ClinicBloc();
