import * as ReactDOM from 'react-dom';

const Portal = ({ children }) => {
  const element = () => document.getElementById('portal-container');

  return element() ? ReactDOM.createPortal(children, element()) : null;
};

export const PortalContainer = () =>  <div id="portal-container"></div>;

export default Portal;
