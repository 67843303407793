import * as rxjs from 'rxjs';
import { notificationService } from '../../../utils/notification';

import React, { createContext, useEffect, useMemo, useState } from 'react';

import { DecodedBloc } from '../../shared/DecodedComponent/bloc';

export class Bloc extends DecodedBloc {
  pharmacyApi;
  constructor(options, apis, pharmacy) {
    super(options);
    this.pharmacyApi = apis.pharmacyApi;
    this.__updateSubject({
      readonly: (pharmacy?.id ?? '') !== '',
      original: pharmacy,
      originalAddress: this.getAddressLabel(pharmacy?.contactInformation?.address),
      pharmacyId: pharmacy?.id ?? '',
      pharmacyCity: pharmacy?.contactInformation?.address.city ?? '',
      pharmacyName: pharmacy?.group ?? pharmacy?.name ?? '',
      pharmacyAddress: pharmacy?.contactInformation?.address ?? {},
      address: pharmacy?.contactInformation?.address ?? {},
    });
  }

  initialise = () => {
    const { pharmacyId } = this.subject.value;

    this.pharmacyApi.pharmacies().then(
      (value) => {
        let others = {};

        const pharmacies = value.data.items;
        if (pharmacyId !== '') {
          const target = pharmacies.find((pharmacy) => pharmacyId === pharmacy.id);
          if (target) {
            others.pharmacyCity = target?.contactInformation?.address.city ?? '';
            others.pharmacyAddress = target?.contactInformation?.address ?? {};
            others.address = target?.contactInformation?.address ?? {};
            others.originalAddress = this.getAddressLabel(target?.contactInformation?.address);
          }
        }
        const cities = this.__getCities(pharmacies);
        const groups = this.__getGroups(pharmacies);
        const addresses = this.__getGroupAddresses(pharmacies);

        this.__updateSubject({
          pharmacies,
          cities,
          groups,
          addresses,
          initialising: false,
          ...others,
        });
      },
      (reason) => notificationService.error('Unable to load pharmacies.'),
    );
  };

  __getCities = (pharmacies) => {
    return pharmacies.reduce((acc, pharmacy) => {
      const city = pharmacy.contactInformation.address.city;
      if (!acc.includes(city)) {
        acc.push(city);
      }
      return acc;
    }, []);
  };

  __getGroups = (pharmacies) => {
    let result = {};

    pharmacies.forEach((pharmacy) => {
      if (pharmacy.contactInformation?.address?.city) {
        if (!(pharmacy.contactInformation.address.city in result)) {
          result[pharmacy.contactInformation.address.city] = [];
        }
        result[pharmacy.contactInformation.address.city].push(pharmacy.group);
      }
    });

    return result;
  };
  __getGroupAddresses = (pharmacies) => {
    let result = {};

    pharmacies.forEach((pharmacy) => {
      if (!(pharmacy.group in result)) {
        result[pharmacy.group] = [];
      }
      const address = this.getAddressLabel(pharmacy.contactInformation.address);
      result[pharmacy.group].push({ id: pharmacy.id, label: address });
    });

    for (const group in result) {
      result[group].push({ id: '-1', label: 'None of these' });
    }

    return result;
  };

  getAddressLabel = (address) => {
    if (!address) return 'Unknown address';
    return typeof address === 'string' ? address : Object.values(address).join(', ');
  };

  __filterGroupAddresses = (selectedCity) => {
    let result = {};

    this.subject.value.pharmacies.forEach((pharmacy) => {
      if (!(pharmacy.group in result)) {
        result[pharmacy.group] = [];
      }
      const address = this.getAddressLabel(pharmacy.contactInformation.address);

      if (pharmacy.contactInformation.address.city === selectedCity) {
        result[pharmacy.group].push({ id: pharmacy.id, label: address });
      }
    });

    for (const group in result) {
      result[group].push({ id: '-1', label: 'None of these' });
    }

    return result;
  };

  setPharmacyData = (data) => {
    this.__updateSubject({ ...data });

    if (data.pharmacyCity) {
      const addresses = this.__filterGroupAddresses(data.pharmacyCity);
      this.__updateSubject({
        addresses,
        pharmacyName: '',
        pharmacyId: '',
      });
    }

    if (data.pharmacyName) {
      this.__updateSubject({
        pharmacyId: '',
      });
    }
  };

  setPharmacyAddressTextChange = (data) => {
    let { address } = this.subject.value;
    const newAddress = { ...address, ...data };
    this.__updateSubject({ address: newAddress });
  };

  handleAddressAutoCompleteChange = (formValues) => {
    let { address } = this.subject.value;
    const newAddress = {
      ...address,
      line1: formValues.line1,
      city: formValues.city,
      administrativeArea: formValues.administrativeArea,
      postcode: formValues.postalCode,
    };

    this.__updateSubject({ address: newAddress });
  };

  edit = () => {
    this.__updateSubject({
      readonly: false,
      pharmacyId: '',
      pharmacyName: '',
      pharmacyCity: '',
      pharmacyAddress: {},
      address: {},
    });
  };

  submit = () => {
    let { pharmacyId, pharmacyName, address, readonly } = this.subject.value;
    return {
      isPharmacyDirty: !readonly,
      id: pharmacyId,
      name: pharmacyName,
      group: pharmacyName,
      contactInformation: {
        address: address,
      },
    };
  };
}

export class BlocEvent {
  static INITIALISED = 'INITIALISED';
}
