import React, { Component } from 'react';

import { OTSubscriber } from 'opentok-react';

import withStyles from '@mui/styles/withStyles';
import { clinicBloc } from '../Secure/VirtualClinic/clinic.bloc';

const styles = (theme) => ({
  root: {
    backgroundColor: '#000000',
  },
  dialin: {
    display: 'none',
    backgroundColor: '#000000',
    position: 'absolute',
    top: '0',
    left: '0',
  },
  fill: {
    flex: '1 1 auto',
  },
  video: {
    width: (props) => (props.isChatOpen ? '60%' : '100%'),
    height: '100%',
  },
});

class Subscriber extends Component {
  constructor(props) {
    super(props);

    this.otSubscriber = React.createRef();

    this.state = {
      error: null,
      audio: true,
      video: true,
      dialinText: 'UNKNOWN',
    };
  }

  componentDidMount() {
    let display = 'WEBRTC';
    let dialinText = 'UNKNOWN';

    if (this.otSubscriber.current?.state?.stream?.connection?.data?.indexOf('"sip"') > 0) {
      const data = JSON.parse(this.otSubscriber.current.state.stream.connection.data);
      if (data.sip) {
        display = 'DIALIN';
        dialinText = data.name?.replace(/'/g, '');
      }

      clinicBloc.setDialinInfo(data);
    }

    this.setState({
      display: display,
      video: display === 'WEBRTC',
      dialinText: dialinText,
    });
  }

  componentWillUnmount() {
    clinicBloc.setDialinInfo({});
  }

  onError = (err) => {
    this.setState({ error: `Failed to subscribe: ${err.message}` });
  };

  render() {
    const { classes } = this.props;

    const { display } = this.state;

    let properties = {
      height: '100vh',
      width: '100%',
      resolution: '1280x720',
      subscribeToAudio: this.state.audio,
      subscribeToVideo: this.state.video,
      videoSource: this.state.videoSource === 'screen' ? 'screen' : undefined,
    };

    if (display === 'DIALIN') {
      properties.height = '0';
      properties.width = '0';
      properties.subscribeToVideo = false;
      properties.videoSource = undefined;
    }

    let root = classes.root;

    if (display === 'DIALIN') {
      root = classes.dialin;
    }

    return (
      <div className={root}>
        {this.state.error ? <div>{this.state.error}</div> : null}
        <OTSubscriber
          ref={this.otSubscriber}
          className={classes.video}
          properties={properties}
          onError={this.onError}
          retry={true}
          maxRetryAttempts={3}
          retryAttemptTimeout={2000}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Subscriber);
