import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import { Alert } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Collapse, Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { isIOS } from 'react-device-detect';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BackButton from '../BackButton';
import {
  getFullMobileLogoBasedOnTenant,
  getLogoBasedOnTenant,
  getMobileLogoBasedOnTenant,
} from '../../../utils/logo';

const styles = (theme) => ({
  root: {
    position: 'fixed',
    // position: "relative",
    width: '100%',
    height: '100vh',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'transparent',
  },
  container: {
    // height: "100%",
    // minHeight: "100%",
    flex: '1',
    marginRight: 'auto',
    marginLeft: '10em',
    maxWidth: '700px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0em',
    },
  },
  appBar: {
    padding: '3em',
  },
  appBarContainer: {
    marginRight: 'auto',
    marginLeft: '10em',
    maxWidth: '700px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0em',
    },
  },

  mobileAppBar: {
    padding: '2em',
    position: 'relative',
  },

  paper: {
    height: '100%',
    // minHeight: "100%",
    width: '100%',
    paddingBottom: '2em',
    display: 'flex',
    flexDirection: 'column',
  },
  navigationButtonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: '1em',
    [theme.breakpoints.down('sm')]: {
      paddingTop: isIOS ? '50px' : '0px',
      paddingLeft: '0rem',
    },
  },
  paperContent: {
    height: '100%',
    padding: isIOS ? '3em 3em 10em 3em' : '3em 3em 5em 3em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      paddingLeft: '0em',
      paddingRight: '0em',
      paddingBottom: isIOS ? '120px' : '60px',
      paddingTop: '5px',
    },
  },

  appBarLogo: {
    display: 'block',
    height: 'auto',
  },

  mobileLogo: {
    // display: 'block',
    // height: 'auto',
    // width: '100%',
    // objectFit: 'fill',
    // position: 'absolute',
    // top: '0.5em',
    // right: '0.5em',
  },

  pageLinkButtonGroup: {
    marginTop: '2em',
  },

  lineProgress: {
    display: 'flex',
    justifyContent: 'center',
  },

  alertTop: {
    position: 'fixed',
    top: '0',
    width: '100%',
    fontSize: '1em',
    zIndex: '1000',
  },
  alertRoot: {
    backgroundColor: '#ef0808',
    boxShadow: '0 5px 5px rgba(0,0,0,.2)',
    borderRadius: '0px',
  },
  alertMessage: {
    fontWeight: '500',
    textAlign: 'right',
    color: '#20516A',
  },
  backArrow: {
    position: 'absolute',
    top: '0.5em',
    left: '0.5em',
    bottom: '0.5em',
  },
});

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    position: 'fixed',
    top: '0px',
    width: '100%',
    zIndex: '1500',
  },
}))(LinearProgress);

class PageContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertOpen: false,
      isDesktop: false,
      drawerOpen: false,
    };
  }

  componentDidMount() {
    this._updateOnResize();
    window.addEventListener('resize', this._updateOnResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._updateOnResize);
  }

  _updateOnResize = () => {
    this.setState({ isDesktop: window.innerWidth > 600 });
  };

  _handleAlertClose = () => {
    this.setState({ alertOpen: false });
  };

  _renderDesktopToolBar = () => {
    const { classes } = this.props;

    return (
      <Container
        maxWidth="sm"
        disableGutters={this.props.isChatScreen}
        className={classes.appBarContainer}
      >
        <div color="transparent" className={classes.appBar}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <img src={getLogoBasedOnTenant()} className={classes.appBarLogo} alt="Logo" />
            </Grid>
          </Grid>
        </div>
      </Container>
    );
  };

  _renderMobileToolBar = () => {
    const { classes, handleBack, handleBackIcon, isEnd } = this.props;

    return (
      <Grid
        container
        direction="row"
        justifyContent={!isEnd ? 'flex-end' : 'flex-start'}
        color="transparent"
        className={classes.mobileAppBar}
      >
        {handleBack && (
          <IconButton
            aria-label="back"
            onClick={handleBack}
            color="primary"
            className={classes.backArrow}
          >
            {handleBackIcon ? handleBackIcon : <ArrowBackIcon fontSize="large" />}
          </IconButton>
        )}
        <Grid item>
          {!isEnd ? (
            <img src={getMobileLogoBasedOnTenant()} className={classes.mobileLogo} alt="Logo" />
          ) : (
            <img
              src={getFullMobileLogoBasedOnTenant()}
              className={classes.mobileLogo}
              alt="Full logo"
            />
          )}
        </Grid>
      </Grid>
    );
  };

  render() {
    const { alertOpen, isDesktop } = this.state;
    const { classes, padding, loading, isChatScreen, handleBack, handleBackIcon } = this.props;
    const paperStyle = {};

    if (padding) {
      paperStyle.padding = padding;
    }

    return (
      <>
        <div id="portal-container"></div>
        <div className={classes.root}>
          <div className={classes.lineProgress}>{loading && <BorderLinearProgress />}</div>

          <div className={classes.alertTop}>
            <Collapse in={alertOpen}>
              <Alert
                classes={{
                  root: classes.alertRoot,
                  message: classes.alertMessage,
                }}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={this._handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                icon={false}
              >
                <Typography variant="body1">
                  <FormattedMessage id={'global.alert.general'} defaultMessage={'_'}>
                    {(chunks) => (chunks[0] === '_' ? null : chunks)}
                  </FormattedMessage>
                </Typography>
              </Alert>
            </Collapse>
          </div>

          {isDesktop ? this._renderDesktopToolBar() : this._renderMobileToolBar()}

          <Container maxWidth="sm" disableGutters={isChatScreen} className={classes.container}>
            <div style={{ ...paperStyle }} className={classes.paperContent}>
              {isDesktop && handleBack && (
                <div>
                  <BackButton handleBackIcon={handleBackIcon} handleBack={handleBack} />
                </div>
              )}

              {this.props.children}
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(PageContainer);
