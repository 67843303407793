import React from 'react';

import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { withRouter } from 'react-router-dom';
import PageContainer from '../common/PageContainer';
import { Grid, styled } from '@mui/material';
import DecodedComponent from '../shared/DecodedComponent';
import { Bloc } from './bloc';
import { ScrollableQuinnContainer } from '../common/ScrollableContainer';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';

const styles = (theme) => ({
  grid: {
    flex: '1',
    paddingTop: '2em',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '1em',
    marginLeft: '1em',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-start',
    },
  },
  textItem: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-start',
    },
  },
  button: {
    width: '100%',
    padding: '1em',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    },
  },
  checkinText: {
    fontWeight: 500,
    fontSize: 18,
    textAlign: 'center',
    color: '#71675E',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      paddingTop: '3em',
    },
  },
  estimationText: {
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'center',
    color: '#71675E',
    paddingTop: '2.4em',
    paddingBottom: '3em',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      paddingBottom: '1.5em',
      paddingTop: 0,
    },
  },
  phoneNumberText: {
    color: '#71675E',
    fontWeight: '500',
    fontSize: 18,
    textAlign: 'center',
    paddingBottom: '2.3em',
    flexDirection: 'row',
    alignContent: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      paddingBottom: '3em',
    },
  },
  phoneNumber: {
    color: theme.palette.primary.main,
  },
  noteText: {
    fontWeight: '550',
    fontSize: 14,
    textAlign: 'center',
    color: '#1A0C00',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      marginTop: '4em',
    },
  },
  waitingTime: {
    fontWeight: '500',
    color: theme.palette.primary.main,
  },
  queueNumber: {
    fontWeight: '700',
    color: theme.palette.primary.main,
    fontSize: '10em',
  },
  directionLink: {
    fontSize: '1.2em',
    color: '#fffff',
  },
  directionLinkText: {
    fontSize: '1.3em',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  linkBox: {
    fontWeight: 700,
    textDecoration: 'none',
    paddingBottom: '5em',
    paddingTop: '3em',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '4em',
    },
  },
  icon: {
    width: '1.8em',
    paddingRight: '0.3em',
  },
  homeLink: {
    fontSize: '2em',
    fontWeight: '1',
  },
  progress: {
    color: theme.palette.primary.main,
  },
  cancelLinkText: {
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  getDirection: {
    flexDirection: 'row',
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  getDirectionIcon: {
    marginRight: 10,
  },
  phoneNumberMargin: {
    margin: 0,
  },
});

const initState = {
  initialising: true,
};

const H1TextTitleSecondary = styled(Typography, { variant: 'h1' })(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '40px !important',
  lineHeight: '32px',
}));

const H2TextTitle = styled(Typography, { variant: 'h2' })(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '20px !important',
  lineHeight: '32px',
}));

class CheckinException extends DecodedComponent {
  constructor(props) {
    super(props);
    this.state = initState;

    analyticsEventLogger.log(AnalyticsEvent.CHECKIN_ERROR, { reason: 'frontdesk' });

    this.bloc = new Bloc();
  }

  render() {
    const { initialising } = this.state;

    return (
      <PageContainer loading={initialising}>
        <ScrollableQuinnContainer
          messageId="checkin.exception.bubble"
          message={`We were unable to find your reservation.`}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ margin: '64px 0' }}>
              <H1TextTitleSecondary sx={{ textAlign: 'center' }}>
                <FormattedMarkdown id={'checkin.exception.title'} defaultMessage={'Thank you!'} />
              </H1TextTitleSecondary>
            </Grid>
            <Grid item xs={12}>
              <H2TextTitle sx={{ textAlign: 'center' }}>
                <FormattedMarkdown
                  id={'checkin.exception.text'}
                  defaultMessage={
                    'Unfortunately we could not check you in via self service. Please proceed to the front desk to complete your check in.'
                  }
                />
              </H2TextTitle>
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(withRouter(CheckinException));
