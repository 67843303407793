import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, Button, styled } from '@mui/material';
import { TextSmallStandard } from '../../shared/Typography'
import { dateUtil } from '../../../utils/date'

const StyledAccessTimeIcon = styled(AccessTimeIcon)((props) => ({
  color: props.isSelected ? '#ffffff' : undefined,
}));

const StyledTimeIntervalButton = styled(Button)(({ theme, isSelected }) => ({
  color: isSelected ? undefined : 'rgba(0, 0, 0, 0.87)',
  backgroundColor: isSelected ? undefined : '#F6F6F6',
  padding: '12px',
  marginRight: '24px',
  marginBottom: '24px',
  width: '145px',
  maxWidth: '145px',
  borderRadius: '8px',
  [theme.breakpoints.down('sm')]: {
    minWidth: '135px',
    width: '135px',
    maxWidth: '135px',
  },
  '&:hover': {
    color: isSelected ? undefined : 'rgba(0, 0, 0, 0.87)',
    backgroundColor: isSelected ? undefined : '#d5d5d5',
  },
}));

export const TimeIntervalButton = ({ isSelected, children, ...props }) => {
  return (
    <StyledTimeIntervalButton
      {...props}
      variant="contained"
      color={isSelected ? 'primary' : undefined}
      isSelected={isSelected}
    >
      <Box
        component={'div'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <StyledAccessTimeIcon color="accent" isSelected={isSelected} /> <TextSmallStandard sx={{ fontWeight: '500'}}>{dateUtil.getUserTimeZoneAbbreviation()}</TextSmallStandard>
      </Box>
      {children}
    </StyledTimeIntervalButton>
  );
};
