import {Box, Button, Grid, Typography} from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import PageContainer from '../../../common/PageContainer';
import ChatBubble from '../../../shared/ChatBubble';
import DecodedComponent from "../../../shared/DecodedComponent";
import withStyles from "@mui/styles/withStyles";
import InsuranceContext from "../context";
import {Component} from "react";
import {PageContent, PageFooter, PagePanel, ScrollableQuinnContainer} from "../../../common/ScrollableContainer";
import DecodedButton from "../../../common/DecodedButton";
import { H2TextTitleContent } from "../../../shared/Typography";
import {FormattedMarkdown} from "@decodedhealth/react-library";


const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});


class InsuranceAccept extends Component {

  constructor(props) {
    super(props);
  }
  render() {

    return (
        <PageContainer onBack={() => { this.props.context.bloc.closeAccept(); }}>
          <ScrollableQuinnContainer messageId="insurance.acceptStatement.bubble"
                                    message="Thank you for your visit. To continue, please accept the below statement.">
          <PageContent>
            <Grid container>
                <Grid  xs={12}>
            <H2TextTitleContent>
              <FormattedMarkdown
                  id="insurance.acceptStatement.text1"
                  defaultMessage={`We will bill your insurance with the information provided. If your insurance does not cover your visit or you owe a patient copay or deductible, you will be responsible for your share of cost. \n\nIf you are uninsured or require financial assistance, please review the payment options that are available on your statement.`}
              />
            </H2TextTitleContent>
                </Grid>
            </Grid>
          </PageContent>
              <PageFooter>
                  <DecodedButton onClick={() => { this.props.context.bloc.accept(); }}>Accept</DecodedButton>
              </PageFooter>
          </ScrollableQuinnContainer>
        </PageContainer>
    );
  }
}

export default withStyles(styles)((props) => (
    <InsuranceContext.Consumer>
      {(value) => {
        return <InsuranceAccept context={value} {...props} />;
      }}
    </InsuranceContext.Consumer>
));
