import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import { Grid } from '@mui/material';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { H2TextTitleContent } from '../../shared/Typography';
import DecodedButton from '../../common/DecodedButton';
import { routeUtil } from '../../../utils/route.name';
import { PageContent, PageFooter } from '../../common/ScrollableContainer';

const styles = (theme) => ({});

class OccHealth extends Component {
  constructor(props) {
    super(props);
    this.bloc = props.bloc;
  }

  render() {
    const { classes, history } = this.props;
    const { appointment } = this.props.bloc.subject.value;

    return (
      <>
        <PageContent>
          <Grid item xs={12} sx={{ margin: '64px 0' }}>
            <H2TextTitleContent>
              <FormattedMarkdown
                id={'booking.checkin.occhealth.text'}
                defaultMessage={
                  'Looks like we got all the information we need from your employer.\n' +
                  '\n' +
                  'Please click Check-In to let the staff know you are here.'
                }
              />
            </H2TextTitleContent>
          </Grid>
        </PageContent>
        <PageFooter>
          <Grid item xs={12} sm={6}>
            <DecodedButton
              onClick={() => {
                this.bloc.checkin();
              }}
            >
              <FormattedMarkdown
                id={'booking.checkin.process.standard.button'}
                defaultMessage={'Continue'}
              ></FormattedMarkdown>
            </DecodedButton>
          </Grid>
        </PageFooter>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(OccHealth));
