import axios from 'axios';
import { VERSION } from '../version';
import { tenantUtil } from '../tenant';

class InsurancesApi {
  constructor() {
    this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || '';

    this.instance = axios.create();
    this.instance.interceptors.request.use(
      async (config) => {
        config.headers['X-DH-source'] = `Consumer Webapp`;
        config.headers['X-DH-version'] = VERSION;
        config.headers['Authorization'] = `Bearer ${tenantUtil.tenantToken()}`;
        config.headers['Content-Type'] = `application/json; version=1.0.0`;
        config.headers['X-API-KEY'] = process.env.REACT_APP_DH_API_KEY;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }

  getProviders() {
    return this.instance.get(`${this.baseUrl}/api/insurances/providers`);
  }

  searchPlan(request) {
    return this.instance.post(`${this.baseUrl}/api/insurances/_search`, request);
  }
}

export const insurancesApi = new InsurancesApi();
