import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import { Grid } from '@mui/material';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { H2TextTitleContent } from '../../shared/Typography';

const styles = (theme) => ({});

class Manual extends Component {
  constructor(props) {
    super(props);
    this.bloc = props.bloc;
  }

  render() {
    const { classes, history } = this.props;

    return (
      <>
        <Grid item xs={12} sx={{ margin: '64px 0' }}>
          <H2TextTitleContent>
            <FormattedMarkdown
              id={'booking.checkin.manual.text'}
              defaultMessage={
                'Please see the front desk to complete your check-in process for your visit.'
              }
            />
          </H2TextTitleContent>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(Manual));
