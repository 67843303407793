import { appointmentApi } from '../../utils/services/appointments.api';
import { notificationService } from '../../utils/notification';
import { DecodedBloc } from '../shared/DecodedComponent/bloc';
import { uriStorage } from '../../utils/storage';
import { authService } from '../../utils/auth';
import { providerStorage } from '../../utils/provider.qs';
import { dateUtil } from '../../utils/date';

export class Bloc extends DecodedBloc {
  constructor() {
    super({});

    this.__initialise();
  }

  __initialise = () => {
    authService.logout().then(() => {
      uriStorage.clearPath();
      providerStorage.clearProvider();
    });
    this.__makeInitialised({});
  };
}

export class BlocEvent {
  static INITIALISED = 'INITIALISED';
}
