import React from 'react';

import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { withRouter } from 'react-router-dom';
import PageContainer from '../common/PageContainer';
import { Grid, styled } from '@mui/material';
import DecodedComponent from '../shared/DecodedComponent';
import { Bloc } from './bloc';
import { ScrollableQuinnContainer } from '../common/ScrollableContainer';

const styles = (theme) => ({});

const initState = {
  initialising: true,
};

const H1TextTitleSecondary = styled(Typography, { variant: 'h1' })(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '40px !important',
  lineHeight: '32px',
}));

class Invalid extends DecodedComponent {
  constructor(props) {
    super(props);
    this.state = { ...initState };
    this.bloc = new Bloc();
  }

  render() {
    const { classes } = this.props;
    const { initialising } = this.state;

    return (
      <PageContainer loading={initialising}>
        <ScrollableQuinnContainer
          messageId={'invalid.route.bubble'}
          message={'Seems we have found ourselves in an invalid route.'}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ margin: '64px 0' }}>
              <H1TextTitleSecondary sx={{ textAlign: 'center' }}>
                Invalid route
              </H1TextTitleSecondary>
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(withRouter(Invalid));
