import React from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BackButton = ({ onClick, icon, sx }) => (
  <IconButton aria-label="back" onClick={onClick} color="primary" sx={sx}>
    {icon ? icon : <ArrowBackIcon fontSize="large" />}
  </IconButton>
);

export default BackButton;
