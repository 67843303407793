class PhoneUtil {
  getNumberParts(number) {
    let phone = number.trim().replace(/[()]/g, '');
    const separators = [' ', '-'];
    return phone.split(new RegExp(separators.join('|'), 'g'));
  }

  formatPhoneNumber(number) {
    let components = this.getNumberParts(number);
    return '+1' + components[0] + components[1] + components[2];
  }

  stripCharacters(number) {
    // eslint-disable-next-line no-useless-escape
    let phone = `${number}`.trim().replace(/[()\ -]/g, '');
    return phone;
  }

  formatPhoneNumberForRegistration(number, code, country) {
    let components = this.getNumberParts(number);
    return country === 'US'
      ? {
          type: 'CELL',
          country: code,
          area: components[0],
          prefix: components[1],
          line: components[2],
        }
      : {
          type: 'CELL',
          country: code,
          area: '',
          prefix: '',
          line: number,
        };
  }

  formatPhoneNumberForDisplay(number) {
    return `(${number.area}) ${number.prefix}-${number.line}`;
  }
}

export const phoneUtil = new PhoneUtil();
