import { Subject } from 'rxjs';
import { appointmentApi } from '../../../../../utils/services/appointments.api';

export class AppointmentEventService {
  appointmentStatusPoller;

  appointmentStatusSubject = new Subject();

  startPollingForAppointmentStatus(appointmentId, length) {
    this.stopPollingForAppointmentStatus();
    this._pollAppointmentStatus(appointmentId);
    this.appointmentStatusPoller = setInterval(
      () => {
        this._pollAppointmentStatus(appointmentId);
      },
      length ? length : 10000,
    );
  }

  _pollAppointmentStatus(appointmentId) {
    appointmentApi
      .getAppointmentStatus(appointmentId)
      .then((value) => {
        console.log(value);
        this.updateAppointmentStatus(value.data);
      })
      .catch((reason) => {
        console.error('Error reading status.', reason);
        this.updateAppointmentStatus({ status: 'ERROR' });
      });
  }

  stopPollingForAppointmentStatus() {
    if (this.appointmentStatusPoller) {
      clearInterval(this.appointmentStatusPoller);
    }
  }

  registerAppointmentStatusCallback(callback) {
    return this.appointmentStatusSubject.subscribe(callback);
  }

  updateAppointmentStatus(event) {
    this.appointmentStatusSubject.next(event);
  }
}

export const appointmentEventService = new AppointmentEventService();
