import React from 'react';
import withStyles from '@mui/styles/withStyles';
import DecodedComponent from '../shared/DecodedComponent';
import { withRouter } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Loading } from '../../shared-library';
import PageContainer from '../common/PageContainer';

import { ScrollableQuinnContainer } from '../common/ScrollableContainer';
import { Bloc, BlocEvent } from './bloc';
import { appointmentApi } from '../../utils/services/appointments.api';
import { providerStorage } from '../../utils/provider.qs';
import { analyticsEventLogger } from '../../utils/events';
import { logger } from '../../utils/logging';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

class UrgentCareRouting extends DecodedComponent {
  constructor(props) {
    super(props);
    const { appointmentId } = this.props.match.params;
    const organisationId = providerStorage.getCurrentProvider() || '';
    this.bloc = new Bloc({
      organisationId: organisationId === '' ? undefined : organisationId,
      appointmentId: appointmentId,
      appointmentApi: appointmentApi,
      analyticsLogger: analyticsEventLogger,
    });
    this.state = {
      initialising: true,
    };
  }

  componentDidMount() {
    logger.debug('**UrgentCareRouting component did mount**');
    super.componentDidMount();
    this.bloc.initialise();
  }

  __handleEvent = (event) => {
    const { type, data } = event;

    if (type === BlocEvent.NAVIGATE_TO) {
      this.props.history.push(data.url);
    }
  };

  render() {
    return (
      <PageContainer loading={true} hideBack={true}>
        <ScrollableQuinnContainer
          messageId={`urgentcare.routing.notice.bubble`}
          message="Redirecting to urgentcare."
        >
          <Grid container direction="column" justifyContent="space-between" flex="1">
            <Loading isFullscreen={false} />
          </Grid>
        </ScrollableQuinnContainer>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(withRouter(UrgentCareRouting));
