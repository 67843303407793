import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import InsuranceContext from '../context';
import { Card, CardContent, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { TextStandard } from '../../../shared/Typography';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

class InsuranceEmpty extends Component {
  render() {
    const { onClick } = this.props;

    return (
      <Card
        variant="outlined"
        sx={{ border: '1px solid #F6F6F6', cursor: 'pointer', minHeight: '30%' }}
        onClick={onClick}
      >
        <CardContent sx={{ alignContent: 'center', paddingTop: '24px' }}>
          <TextStandard sx={{ textAlign: 'center' }}>
            <FormattedMessage
              id={'insurance.information.empty.click'}
              defaultMessage={'No Insurances'}
            ></FormattedMessage>
          </TextStandard>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)((props) => (
  <InsuranceContext.Consumer>
    {(value) => {
      return <InsuranceEmpty context={value} {...props} />;
    }}
  </InsuranceContext.Consumer>
));
