import * as React from 'react';
import { useWizardSteps } from './useWizardSteps';
import { WizardContextProvider } from './wizard-context';

/**
 * step = {
 *  id: 'step-id',
 *  component: <DummyComponent />
 * }
 *
 */
export const Wizard = ({ steps, startAtStep, overrides, children }) => {
  const wizardBag = useWizardSteps(steps, startAtStep, overrides);
  return (
    <WizardContextProvider value={wizardBag}>
      {children ?? wizardBag.activeStep.component}
    </WizardContextProvider>
  );
};
