import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';

import { preloadScript } from 'opentok-react';

import Display from './Display';
import TelehealthContext from './context';
import { telehealthEventService } from './service/telehealth.event.service';
import { clinicBloc } from '../Secure/VirtualClinic/clinic.bloc';
import { AnalyticsEvent, analyticsEventLogger } from '../../utils/events';
import { getLogoBasedOnTenant } from '../../utils/logo';

const styles = (theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    width: '100%',
    paddingRight: '0',
  },
  brandingWrapper: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    top: '86px',
    left: '62px',
    [theme.breakpoints.down('md')]: {
      top: '43px',
      left: '31px',
    },
  },
  logo: {
    height: '39px',
  },
  time: {
    marginTop: '40px',
    width: '100px',
    height: '32px',
    paddingTop: '4px',

    background: '#FFFFFF',
    border: '1px solid #F7F7F7',
    boxSizing: 'border-box',
    borderRadius: '22px',

    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#20516A',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  controls: {},
  fill: {
    flex: '1 1 auto',
  },
});

class TelehealthPanel extends Component {
  subscription;

  constructor(props, context) {
    super(props, context);

    this.state = {
      error: null,
      connected: false,
      telehealth: {
        state: 'CONNECTED',
        video: true,
        audio: true,
      },
    };

    this.sessionEvents = {
      sessionConnected: () => {
        this.setState({ connected: true });
      },
      sessionDisconnected: () => {
        this.setState({ connected: false });
      },
    };

    this._handleEvent = this._handleEvent.bind(this);
  }

  componentDidMount() {
    this.subscription = telehealthEventService.registerStateCallback(this._handleEvent);
    clinicBloc.subscribeToState(this._handleState);
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  _handleState = (e) => {
    const { telehealth } = this.state;

    telehealth.audio = e.audio;
    telehealth.video = e.video;

    this.setState({
      telehealth: telehealth,
    });
  };

  _handleEvent = (event) => {
    const { type } = event;

    const { telehealth } = this.state;

    let newState = {};

    switch (type) {
      case 'ENCOUNTER_CALL_JOIN':
        newState.telehealth = telehealth;
        newState.telehealth.state = 'CONNECTED';

        analyticsEventLogger.log(AnalyticsEvent.TELEHEALTH_START, {
          sessionId: this.props.sessionId,
        });
        break;
      case 'ENCOUNTER_CALL_CUSTOMER_END':
        newState.telehealth = telehealth;
        newState.telehealth.state = 'DISCONNECT';

        analyticsEventLogger.log(AnalyticsEvent.TELEHEALTH_END, {
          sessionId: this.props.sessionId,
        });
        break;
      default:
        break;
    }

    this.setState({
      ...newState,
    });
  };

  _renderLogo = () => {
    const { classes } = this.props;
    return <img src={getLogoBasedOnTenant()} className={classes.logo} alt="logo" />;
  };

  render() {
    let { classes, platformId, sessionId, token } = this.props;
    let { busy, telehealth } = this.state;

    let context = {
      busy: busy,
      telehealth: telehealth,
    };

    // eslint-disable-next-line react/react-in-jsx-scope
    return (
      <>
        <TelehealthContext.Provider value={context}>
          <div className={classes.root}>
            <Display
              apiKey={platformId}
              sessionId={sessionId}
              token={token}
              loadingDelegate={<div>Loading...</div>}
            />
          </div>
        </TelehealthContext.Provider>
      </>
    );
  }
}

export default preloadScript(withStyles(styles)(TelehealthPanel));
