import { Subject } from 'rxjs';

export class NotificationService {
  subject = new Subject();

  registerStateCallback(callback) {
    return this.subject.subscribe(callback);
  }

  update(notification) {
    this.subject.next(notification);
  }

  error(message) {
    this.subject.next({ error: message });
  }

  success(message) {
    this.subject.next({ success: message });
  }

  httpError(error) {
    if (error.response?.data?.message) {
      const response = error.response.data;
      this.subject.next({ error: `${this.__lookupMessage(response)}` });
    } else {
      this.subject.next({ error: `${error.message}` });
    }
  }

  __lookupMessage(response) {
    if (response.message.includes('Invalid transition request')) {
      return 'The action is invalid. Please contact support if this problem persists.';
    }

    return `${response.error} - ${response.message}`;
  }
}

export const notificationService = new NotificationService();
