import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';

import { IconButton, Input, InputAdornment } from '@mui/material';

import SendIcon from '@mui/icons-material/Send';
import { notificationService } from '../../utils/notification';
import TelehealthContext from './context';
import { CloudEvent } from 'cloudevents';
import { authService } from '../../utils/auth';
import { format, utcToZonedTime } from 'date-fns-tz';
import { clinicBloc, ClinicBlocEvent } from '../Secure/VirtualClinic/clinic.bloc';
import { eventApi } from '../../utils/services/event.api';

const styles = (theme) => ({
  root: {
    height: '95%',
    width: '100%',
    zIndex: '1000',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 12px 20px 24px',
    [theme.breakpoints.down('sm')]: {
      height: '90%',
    },
  },
  fill: {
    flex: '1 1 auto',
  },
  wrapper: {
    display: 'flex',
  },
  wrapperContent: {
    margin: 'auto',
    paddingBottom: '32px',
  },

  chatInputFormWrapper: {
    position: 'relative',
    paddingTop: '12px',
    paddingRight: '12px',
    paddingBottom: '12px',
  },
  chatInput: {
    cursor: 'pointer',
  },

  click: {
    cursor: 'pointer',
  },

  chat: {
    height: 'inherit',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column-reverse',
  },

  subjectMessageRow: {
    paddingTop: '24px',
    display: 'flex',
    flexDirection: 'column',
  },
  subjectMessageContent: {
    paddingTop: '12px',
    marginLeft: 'auto',
  },
  subjectMessage: {
    borderRadius: '6px',
    padding: '10px 12px',
    backgroundColor: 'rgba(194, 206, 212, 0.2)',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '26px',
    color: '#002F59',
    textAlign: 'right',
  },
  subjectMessageTimestamp: {
    paddingTop: '8px',
    float: 'right',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '22px',
    color: '#828282',
    textAlign: 'right',
  },

  providerMessageRow: {
    paddingTop: '24px',
    display: 'flex',
    flexDirection: 'column',
  },

  providerMessageContent: {
    paddingTop: '12px',
  },
  providerMessage: {
    boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.2)',
    borderRadius: '6px',
    marginTop: '8px',
    padding: '10px 12px',
    backgroundColor: '#00C3FF',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '26px',
    color: '#ffffff',
    textAlign: 'left',
    display: 'inline-block',
  },
  providerMessageTimestamp: {
    paddingTop: '8px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '22px',
    color: '#828282',
  },
});

class Chat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      message: '',
      messages: [],
      encounterId: '',
    };

    const session = props.otSession.current.sessionHelper.session;
    session.off('signal:msg');
    session.on('signal:msg', this._receiveSignal(session));
  }

  componentDidMount() {
    this.stateSubscription = clinicBloc.subscribeToState(this._handleState);
    this.eventSubscription = clinicBloc.subscribeToEvents(this._handleEvent);

    clinicBloc.getChatHistory();
  }

  componentWillUnmount() {
    if (this.stateSubscription) this.stateSubscription.unsubscribe();
    if (this.eventSubscription) this.eventSubscription.unsubscribe();
  }

  _handleState = (e) => {
    this.setState({
      encounterId: e.encounterId,
    });
  };

  _handleEvent = (e) => {
    const { type, data } = e;

    switch (type) {
      case ClinicBlocEvent.CHAT_HISTORY_LOAD_SUCCESS:
        this.setState({
          loading: false,
          messages: data.messages,
        });
        break;

      case ClinicBlocEvent.CHAT_HISTORY_LOAD_ERROR:
        this.setState({
          loading: false,
        });
        break;
      default:
        break;
    }
  };

  _receiveSignal = (session) => (event) => {
    const { messages } = this.state;
    const { isChatOpen } = this.props;

    if (event.from.connectionId !== session.connection.id) {
      if (!isChatOpen) {
        clinicBloc.setMessageUnread(true);
      }

      this.setState({
        messages: [event.data].concat(messages),
      });
    }
  };

  _handleChatTextChange = (event) => {
    this.setState({
      message: event.target.value,
    });
  };

  _keyUp = (event) => {
    const enterKey = 13;
    if (event.which === enterKey) {
      this._send();
    }
  };

  _send = () => {
    let { otSession } = this.props;
    let { message } = this.state;

    if (!message || message.length < 1) return;

    authService.getIdTokenResult().then((token) => {
      const data = {
        author: {
          role: 'SUBJECT',
          identifier: {
            system: 'decoded',
            code: 'id',
            value: token.claims.dh_sub,
          },
        },
        message: message,
      };

      let ce = new CloudEvent({
        type: 'telehealth.chat.message',
        datacontenttype: 'application/json',
        source: '/consumer-webapp',
        dataschema: 'https://schema.decodedhealth.com/events/telehealth.json',
        data,
      });

      ce = ce.cloneWith({ xdhvendor: 'dh' });
      ce = ce.cloneWith({ xdhsystem: 'vonage' });
      ce = ce.cloneWith({ xdhencounter: this.state.encounterId });

      let newMessage = ce.toJSON();

      const session = otSession.current.sessionHelper.session;

      session.signal(
        {
          type: 'msg',
          data: newMessage,
        },
        (error) => this._messageSendCompletionHandler(error, newMessage),
      );

      eventApi.publish(newMessage);
    });
  };

  _messageSendCompletionHandler = (error, newMessage) => {
    const { messages } = this.state;

    if (error) {
      notificationService.error(`Error sending message: ${error.message}.`);
    } else {
      this.setState({
        message: '',
        messages: [newMessage].concat(messages),
      });
    }
  };

  _renderMessageTime = (time) => {
    const displayTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const displayTime = utcToZonedTime(time, displayTimezone);
    const outputFormat = 'p';

    return format(displayTime, outputFormat, { timeZone: displayTimezone });
  };

  _renderChat(message) {
    let { classes } = this.props;

    if (message.data.author.role === 'SUBJECT') {
      return (
        <>
          <div className={classes.subjectMessageRow}>
            <div className={classes.fill}> </div>
            <div className={classes.subjectMessageContent}>
              <div className={classes.subjectMessage}>{message.data.message}</div>
            </div>
            <div className={classes.subjectMessageTimestamp}>
              {this._renderMessageTime(message.time)}
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className={classes.providerMessageRow}>
          <div className={classes.providerMessageContent}>
            <div className={classes.providerMessage}>{message.data.message}</div>
          </div>
          <div className={classes.providerMessageTimestamp}>
            {this._renderMessageTime(message.time)}
          </div>
          <div className={classes.fill}> </div>
        </div>
      </>
    );
  }

  render() {
    let { classes } = this.props;
    let { message, messages } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.chat}>{messages.map((_message) => this._renderChat(_message))}</div>
        <div className={classes.chatInputFormWrapper}>
          <Input
            id="chat-input"
            className={classes.chatInput}
            type={'text'}
            placeholder={'Type a message...'}
            fullWidth
            value={message}
            onKeyUp={this._keyUp}
            onChange={this._handleChatTextChange}
            autoComplete="off"
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="send message" onClick={this._send} size="large">
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)((props) => (
  <TelehealthContext.Consumer>
    {(value) => {
      return <Chat context={value} {...props} />;
    }}
  </TelehealthContext.Consumer>
));
