import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Fab,
} from '@mui/material';

import endIcon from '../../assets/icons/end_call.svg';

import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import { telehealthEventService } from './service/telehealth.event.service';
import TelehealthContext from './context';
import { clinicBloc } from '../Secure/VirtualClinic/clinic.bloc';

const styles = (theme) => ({
  root: {
    position: 'fixed',
    bottom: '0',
    width: (props) => (props.isChatOpen ? '60%' : '100%'),
    zIndex: '1000',
  },
  fill: {
    flex: '1 1 auto',
  },
  wrapper: {
    display: 'flex',
  },
  wrapperContent: {
    margin: 'auto',
    paddingBottom: '32px',
  },
  fab: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  click: {
    cursor: 'pointer',
  },
});

class End extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmEnd: false,
    };
  }

  _endCall = () => {
    telehealthEventService.update({
      type: 'ENCOUNTER_CALL_CUSTOMER_END',
      data: {},
    });
  };

  _closeDialog = () => {
    this.setState({
      confirmEnd: false,
    });
  };

  _confirmEndCallDialog = () => {
    this.setState({
      confirmEnd: true,
    });
  };

  render() {
    let { classes } = this.props;
    let { telehealth } = this.props.context;
    let { confirmEnd } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <span className={classes.wrapperContent}>
            <Fab
              color="primary"
              size="medium"
              aria-label="vid"
              className={classes.fab}
              onClick={clinicBloc.toggleVideo}
            >
              {telehealth.video ? (
                <VideocamIcon fontSize="large" />
              ) : (
                <VideocamOffIcon fontSize={'large'} />
              )}
            </Fab>
            <img
              className={classes.click}
              onClick={this._confirmEndCallDialog}
              src={endIcon}
              alt="End Call"
            />
            <Fab
              color="primary"
              size="medium"
              aria-label="aud"
              className={classes.fab}
              onClick={clinicBloc.toggleAudio}
            >
              {telehealth.audio ? <MicIcon fontSize="large" /> : <MicOffIcon fontSize={'large'} />}
            </Fab>
          </span>
        </div>
        <Dialog
          open={confirmEnd}
          onClose={this._closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to end the call?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color={'secondary'}
              variant={'contained'}
              onClick={this._closeDialog}
              disableElevation
              autoFocus
            >
              No
            </Button>
            <Button
              color={'primary'}
              variant={'contained'}
              onClick={this._endCall}
              disableElevation
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)((props) => (
  <TelehealthContext.Consumer>
    {(value) => {
      return <End context={value} {...props} />;
    }}
  </TelehealthContext.Consumer>
));
