import { logger } from '../../../utils/logging';

/**
 * Validates a name.
 * Assumes a valid name contains only alphabets and spaces, and is between 2 to 50 characters.
 * @param {string} name - The name to validate.
 * @returns {boolean} - True if the name is valid, false otherwise.
 */
export const isValidName = (name, errorMessage) => {
  const regex = /^[A-Za-z\s]{2,50}$/;
  return regex.test(name) || errorMessage;
};

/**
 * Validates a phone number.
 * Assumes a valid phone number contains only numbers and is 10 digits long.
 * @param {string} phoneNumber - The phone number to validate.
 * @returns {boolean} - True if the phone number is valid, false otherwise.
 */
export const isValidPhoneNumber = (phoneNumber) => {
  if (phoneNumber === '(___) ___-____') return false; // Mask's default value
  const regex = /^\(\d{3}\) \d{3}-\d{4}$/; // Adjusted regex to account for parentheses and dashes
  return regex.test(phoneNumber);
};

/**
 * Validates an email.
 * @param {string} email - The email to validate.
 * @returns {boolean} - True if the email is valid, false otherwise.
 */
export const isValidEmail = (email) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return regex.test(email);
};

/**
 * Validates a date.
 * Assumes a valid date is in the format YYYY-MM-DD.
 * @param {string} date - The date to validate.
 * @returns {boolean} - True if the date is valid, false otherwise.
 */
export const isValidDate = (dateString) => {
  const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
  if (!regex.test(dateString)) return false;

  const parts = dateString.split('/');
  const day = parseInt(parts[1], 10);
  const month = parseInt(parts[0], 10);
  const year = parseInt(parts[2], 10);

  if (month < 1 || month > 12) return false;
  if (day < 1 || day > 31) return false;
  if ((month === 4 || month === 6 || month === 9 || month === 11) && day === 31) return false;
  if (month === 2) {
    if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
      if (day > 29) return false;
    } else {
      if (day > 28) return false;
    }
  }

  //--> checks if the date is not older than 110 years
  const currentYear = new Date().getFullYear();
  if (year < currentYear - 110) return false;

  const inputDate = new Date(year, month - 1, day);
  const currentDate = new Date();

  //--> checks if the date is in the future
  if (inputDate > currentDate) return false;

  return true;
};
