import React, { Component } from 'react';
import poster from '../../../../assets/learn-about-fast-track.png';
import withStyles from '@mui/styles/withStyles';
import { Button, Card, CardMedia } from '@mui/material';
import { TextStandard } from '../../../shared/Typography';

const styles = (theme) => ({
  root: {},
  fill: {
    flex: '1 1 auto',
  },
  text: {},
  buttons: {
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  button: {
    marginTop: '8px',
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 1 auto',
    fontWeight: '600',
    padding: '10px',
    appearance: 'none',
    fontSize: '18px',
  },
});

class ButtonCard extends Component {
  state = {
    buttons: [],
  };

  constructor(props) {
    super(props);

    this.__click = this.__click.bind(this);
    this.ref = React.createRef();
  }

  componentDidMount() {
    const { correlationId, options, content, media } = this.props.attachment.payload;

    const showButtons = !sessionStorage.getItem(`button_${correlationId}`);

    this.setState({
      id: correlationId,
      text: content,
      buttons: showButtons ? options : [],
      media: media?.uri,
    });

    if (showButtons) {
      const ref = this.ref;
      setTimeout(() => {
        if (ref.current) {
          ref.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  }

  componentWillUnmount() {}

  __click = (button) => {
    const $this = this;
    const { correlationId } = this.props.attachment.payload;
    return (event) => {
      this.props.bloc.quickTextReply(button.id, button.value);
      sessionStorage.setItem(`button_${correlationId}`, button.value);
      $this.setState({ buttons: [] });
    };
  };

  render() {
    const { classes } = this.props;

    const { text, buttons, media } = this.state;

    return (
      <>
        <div ref={this.ref} className={`${classes.root} rw-response`}>
          <TextStandard>{text}</TextStandard>
          {media && (
            <Card sx={{ margin: '.9rem' }} elevation={5}>
              <CardMedia
                component="video"
                src={media}
                autoPlay={false}
                controls={true}
                poster={poster}
              />
            </Card>
          )}
          <div
            className={`${classes.buttons}`}
            style={
              media
                ? {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    width: '100%',
                  }
                : {}
            }
          >
            {buttons.map((button) => (
              <>
                <Button
                  className={classes.button}
                  color={'accent'}
                  variant={'contained'}
                  onClick={this.__click(button)}
                >
                  {button.value}
                </Button>
              </>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(ButtonCard);
