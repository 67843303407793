import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { FormattedMarkdown } from '@decodedhealth/react-library';
import { H2TextTitleContent } from '../../../../shared/Typography';
import TakeImage from '../../../../shared/TakeImage';

const styles = (theme) => ({});

class BackScan extends Component {
  constructor(props) {
    super(props);
  }

  __next = async () => {
    this.setState({ loading: true });
    const $this = this;
    this.props.handleImageSubmit(() => {
      $this.setState({ loading: false });
    });
  };

  render() {
    let { loading, backImage, handleBackScanCapture, handlePreviousScan } = this.props;

    return (
      <TakeImage
        title={
          <H2TextTitleContent>
            <FormattedMarkdown
              id="customerAccount.insuranceScan.step2.text"
              defaultMessage={'Step 2: Back side of the card'}
            />
          </H2TextTitleContent>
        }
        initialImage={backImage}
        handleScanCapture={handleBackScanCapture}
        handleBack={handlePreviousScan}
        onSubmit={this.__next}
        loading={loading}
      />
    );
  }
}

export default withStyles(styles)(BackScan);
