export class ErrorMessage {
  static CALL_SUPPORT = ' If the problem persists, please contact the clinic.';
}

class ErrorResolver {
  resolvePinErrorDisplay(error) {
    let message = 'Login failed - Invalid pin code provided.';
    return message + ErrorMessage.CALL_SUPPORT;
  }

  resolveAuthErrorDisplay(error) {
    let message = '';

    if (this._hasErrorMessage(error)) {
      if (this._doesErrorMessageStartWith(error, '[0004-0001]')) {
        message = 'Invalid details provided.';
      } else if (
        this._doesErrorMessageStartWith(error, '[0004-0002]') ||
        this._doesErrorMessageStartWith(error, '[0003-0007]')
      ) {
        message = 'Pin code has expired.';
      } else if (this._doesErrorMessageStartWith(error, '[0004-0003]')) {
        message = 'Pin code is invalid.';
      } else if (this._doesErrorMessageStartWith(error, '[0004-0004]')) {
        message = 'Unable to log in.';
      } else {
        message = 'Invalid credentials provided.';
      }

      message += ' Please try again.';

      return message;
    } else if (error.response?.data?.status === 401) {
      message = 'Invalid credentials provided. Please try again.';
      return message;
    }

    return error + ErrorMessage.CALL_SUPPORT;
  }

  resolveRegistrationErrorDisplay(error) {
    if (this._hasErrorMessage(error)) {
      if (this._doesErrorMessageStartWith(error, '[0001-1005]')) {
        return 'This user already exists. Please verify the users details and try again.';
      }

      if (
        this._doesErrorMessageStartWith(error, '[0003-0003]') ||
        this._doesErrorMessageStartWith(error, '[0003-0002]')
      ) {
        return (
          'Potential conflict detected. Please verify the users details and try again.' +
          ErrorMessage.CALL_SUPPORT
        );
      }

      if (error.response.data.status >= 500) {
        return 'Internal service error.' + ErrorMessage.CALL_SUPPORT;
      }

      let message = error.response.data.message.replace(
        /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g,
        '',
      );
      if (
        message.indexOf('validation.') > -1 &&
        (message.indexOf('.invalid') > -1 || message.indexOf('.required') > -1)
      ) {
        message = this._handleInvalidOrRequiredFields(message);
      }

      return message;
    }

    return error.message || error;
  }

  resolveBookingConfirmationError(error) {
    if (error.response.data.status === 408) {
      return 'Your time to complete the reservation has been exceeded. Please start the reservation again.';
    }

    let message = `Error confirming the visit. Error - ${error}.`;
    return message + ErrorMessage.CALL_SUPPORT;
  }

  resolvePaymentDetailsRetrievalError(error) {
    let message = `Error checking your payment details. Error - ${error}.`;
    return message + ErrorMessage.CALL_SUPPORT;
  }

  _handleInvalidOrRequiredFields(message) {
    const lastIndexOfRequired = message.lastIndexOf('.required') + '.required'.length;
    const lastIndexOfValid = message.lastIndexOf('.invalid') + '.invalid'.length;
    const lastIndexOf =
      lastIndexOfValid > lastIndexOfRequired ? lastIndexOfValid : lastIndexOfRequired;
    const firstIndexOf = message.indexOf('validation.');

    if (firstIndexOf < lastIndexOf) {
      const list = message
        .slice(firstIndexOf, lastIndexOf)
        .split(', ')
        .map((_item) => _item.replace('validation.', ''))
        .map((_item) => _item.replace(/\./g, ' '))
        .map((_item) => _item.replace(/([A-Z])/g, ' $1').toLowerCase());

      return `Please review the following fields as they are invalid or required: ${list.join(
        ', ',
      )}`;
    }

    return message;
  }

  _hasErrorMessage(error) {
    return (
      error.response &&
      error.response.data &&
      (error.response.data.message || error.response.data.message === '')
    );
  }

  _doesErrorMessageStartWith(error, code) {
    return error.response.data.message.startsWith(code);
  }
}

export const errorResolver = new ErrorResolver();
