import { Button, Grid } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SelectValidator, TextValidator } from 'react-material-ui-form-validator';
import { FlexValidatorForm } from '../../../../common/FlexValidatorForm';
import PageContainer from '../../../../common/PageContainer';
import {
  DefaultDisconnectedPageFooter,
  ScrollableQuinnContainer,
} from '../../../../common/ScrollableContainer';
import withStyles from '@mui/styles/withStyles';
import DecodedComponent from '../../../../shared/DecodedComponent';
import MenuItem from '@mui/material/MenuItem';
import { WizardContextConsumer } from '../../../../../shared-library/components/Wizard/wizard-context';
import { Select } from '../../../../Registration/components/Select';
import { GENDER_MANDATORY_OPTIONS } from '../../../../../utils/user';
import { DateMask } from '../../../../shared/InputTextMask';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

class PolicyHolderForm extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = props.bloc;
    this.state = {
      ...this.bloc.subject.value,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  __handleTextChange = (event) => {
    this.bloc.handlePolicyHolderTextChange(event.target.name, event.target.value);
  };

  render() {
    const { classes, context } = this.props;
    const { form, relationshipCodes } = this.state;
    const { policyHolder } = form;

    return (
      <PageContainer
        onBack={() => {
          this.bloc.back(context);
        }}
      >
        <FlexValidatorForm
          onSubmit={() => {
            this.bloc.next(context);
          }}
        >
          <ScrollableQuinnContainer
            messageId="insurance.policyHolder.form.bubble"
            message="Please tell us about the insurance policy holder."
          >
            <Grid container spacing={3} sx={{ paddingBottom: '24px' }}>
              {relationshipCodes && (
                <Grid item xs={12} sm={12}>
                  <SelectValidator
                    fullWidth
                    select
                    id="relationship"
                    name="relationship"
                    label={`The relationship of the policy holder to the patient`}
                    value={policyHolder.relationship}
                    onChange={this.__handleTextChange}
                  >
                    {relationshipCodes.map((r, i) => (
                      <MenuItem key={i} value={r.code}>
                        {r.value}
                      </MenuItem>
                    ))}
                  </SelectValidator>
                </Grid>
              )}
              {policyHolder.relationship && policyHolder.relationship !== 'SELF' ? (
                <>
                  <Grid item xs={12} sm={12}>
                    <TextValidator
                      required
                      fullWidth
                      autoFocus={true}
                      id="firstName"
                      label="First Name"
                      name="firstName"
                      autoComplete="off"
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      value={policyHolder.firstName}
                      validators={['isValidName']}
                      errorMessages={[
                        <FormattedMessage
                          id="registration.field.error.patient.name.given"
                          defaultMessage={'Invalid name.'}
                        />,
                      ]}
                      onChange={this.__handleTextChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextValidator
                      required
                      fullWidth
                      id="lastName"
                      label={
                        <FormattedMessage
                          id="registration.field.label.lastName"
                          defaultMessage={'Last Name'}
                        />
                      }
                      type="text"
                      name="lastName"
                      autoComplete="off"
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      value={policyHolder.lastName}
                      validators={['isValidName']}
                      errorMessages={[
                        <FormattedMessage
                          id="registration.field.error.patient.name.family"
                          defaultMessage={'Invalid name.'}
                        />,
                      ]}
                      onChange={this.__handleTextChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      id="gender"
                      name="gender"
                      label="Sex"
                      required
                      value={policyHolder.gender}
                      onChange={this.__handleTextChange}
                      options={GENDER_MANDATORY_OPTIONS}
                      getItemLabel={(item) => item.label}
                      getItemValue={(item) => item.value}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextValidator
                      required
                      fullWidth
                      id="dateOfBirth"
                      label={
                        <FormattedMessage
                          id="registration.field.label.dob"
                          defaultMessage={'Date of Birth'}
                        />
                      }
                      name="dateOfBirth"
                      placeholder="MM/dd/yyyy e.g. 12/31/2000"
                      autoComplete="bday"
                      value={policyHolder.dateOfBirth}
                      onChange={this.__handleTextChange}
                      validators={['isDateValid']}
                      errorMessages={[
                        <FormattedMessage
                          id="registration.field.error.dob"
                          defaultMessage={'Invalid date of birth'}
                        />,
                      ]}
                      InputProps={{
                        inputComponent: DateMask,
                      }}
                      inputProps={{ inputMode: 'numeric' }}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </ScrollableQuinnContainer>
          <DefaultDisconnectedPageFooter justifyContent={'left'}>
            <Grid item xs={12} sm={6}>
              <Button type="submit" variant="contained" color="primary">
                <FormattedMessage
                  id="insurance.policyholder.form.button.label.submit"
                  defaultMessage={'Save'}
                />
              </Button>
            </Grid>
          </DefaultDisconnectedPageFooter>
        </FlexValidatorForm>
      </PageContainer>
    );
  }
}

export default withStyles(styles)((props) => (
  <WizardContextConsumer>
    {(value) => {
      return <PolicyHolderForm context={value} {...props} />;
    }}
  </WizardContextConsumer>
));
