import { DecodedBloc } from '../shared/DecodedComponent/bloc';
import { authService } from '../../utils/auth';
import { providerStorage } from '../../utils/provider.qs';

export class Bloc extends DecodedBloc {
  constructor(appointmentId) {
    super({});
    this.__initialise();
  }

  __initialise = () => {
    authService.logout();
    providerStorage.clearProvider();
    this.__updateSubject({ initialising: false });
  };
}

export class BlocEvent {
  static INITIALISED = 'INITIALISED';
}
