import { Subject } from 'rxjs';
import { eventApi } from '../../../utils/services/event.api';
import { logger } from '../../../utils/logging';

export class SecureEventService {
  remotePublisher;

  subject = new Subject();

  startRemotePublishing() {
    this.stopRemotePublishing();
    this._publishActivePing();
    this.remotePublisher = setInterval(() => {
      this._publishActivePing();
    }, 1000 * 60);
  }

  _publishActivePing() {
    eventApi.publish({ type: 'active' }).then((value) => {
      logger.debug(value);
    });
  }

  stopRemotePublishing() {
    if (this.remotePublisher) {
      clearInterval(this.remotePublisher);
    }
  }

  registerStateCallback(callback) {
    return this.subject.subscribe(callback);
  }

  update(event) {
    this.subject.next(event);
  }
}

export const secureEventService = new SecureEventService();
