import React, { Component } from 'react';

import withStyles from '@mui/styles/withStyles';
import PageContainer from '../../../shared/Container';
import { uriStorage } from '../../../../utils/storage';
import { paymentApi } from '../../../../utils/services/payment.api';
import { notificationService } from '../../../../utils/notification';
import { CircularProgress, Grid, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import ChatBubble from '../../../shared/ChatBubble';
import Button from '@mui/material/Button';
import { ACCOUNT_PAYMENT_METHOD_UPDATE_ROUTE, QUINN_ROUTE } from '../../../../utils/route.name';
import { AnalyticsEvent, analyticsEventLogger } from '../../../../utils/events';
import { userInfoUtil } from '../../../../utils/user';
import { errorResolver } from '../../../../utils/error.resolver';
import { buttonGroupStyle } from '../../../shared/CommonStyle';
import { FormattedMarkdown } from '@decodedhealth/react-library';

const styles = (theme) => ({
  ...buttonGroupStyle(theme),
  grid: {
    flex: '1',
    width: '100%',
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  progressBox: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progress: {
    minWidth: '100%',
    minHeight: '100%',
    margin: '0 auto',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#F29202',
  },
  title: {
    paddingTop: '1em',
    color: '#FF7700',
  },
  type: {
    paddingTop: '0.9em',
    fontSize: '2em',
  },
  validation: {
    flex: '1',
    width: '100%',
    paddingTop: '2em',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100%',
  },
  dataDisplay: {
    flex: '1',
    width: '100%',
  },
  buttonGroup: {
    paddingTop: '3em',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  button: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  insuranceImage: {
    paddingTop: '1em',
    width: '100%',
    height: '100%',
  },
});

const initMessage = {
  id: 'paymentMethod.view.bubble.init',
  text: 'We are looking for your current payment details.',
};
const paymentMessage = {
  id: 'paymentMethod.view.bubble.success',
  text: 'We have these payment details on record. Are they still correct of would you like to update?',
};
const errorMessage = {
  id: 'paymentMethod.view.bubble.error',
  text: 'We could not load payment details from record.',
};

const initState = {
  loading: true,
  assistantMessage: initMessage,
  existingPaymentMethods: {
    memberId: '',
    provider: '',
    planType: '',
    userPlan: '',
    member: {},
    paymentType: '',
  },
  insuranceCard: undefined,
};

class PaymentMethod extends Component {
  constructor(props) {
    super(props);
    this.state = initState;
  }

  componentDidMount() {
    uriStorage.setCurrentPath(this.props.match.url);

    analyticsEventLogger.log(AnalyticsEvent.PAYMENT_METHOD_REVIEW_OPEN);

    this._retrievePaymentMethod();
  }

  _retrievePaymentMethod = () => {
    paymentApi
      .getPaymentMethods()
      .then((value) => {
        analyticsEventLogger.log(AnalyticsEvent.PAYMENT_METHOD_RETRIEVAL_SUCCESS);

        if (!value.data.items) {
          this.props.history.replace(ACCOUNT_PAYMENT_METHOD_UPDATE_ROUTE);
        } else {
          this.buildExistingPaymentData(value);
        }
      })
      .catch((error) => {
        analyticsEventLogger.log(AnalyticsEvent.PAYMENT_METHOD_RETRIEVAL_ERROR, {
          reason: error,
        });

        notificationService.error(errorResolver.resolvePaymentDetailsRetrievalError(error));

        this.setState({
          assistantMessage: errorMessage,
          loading: false,
        });
      });
  };

  _retrieveInsuranceCardImage = () => {
    this.setState({ loading: true });
    paymentApi
      .getInsuranceCard()
      .then((response) => {
        analyticsEventLogger.log(AnalyticsEvent.PAYMENT_METHOD_INSURANCE_CARD_RETRIEVAL_SUCCESS);

        this.setState({ insuranceCard: response.data });
      })
      .catch((error) => {
        if (error.response?.status !== 404) {
          analyticsEventLogger.log(AnalyticsEvent.PAYMENT_METHOD_INSURANCE_CARD_RETRIEVAL_ERROR, {
            reason: error,
          });

          notificationService.error('Unable to retrieve your insurance card images.');
        }
      })
      .finally(() => {
        this.setState({
          loading: false,
          assistantMessage: paymentMessage,
        });
      });
  };

  buildExistingPaymentData = (response) => {
    const data = response.data.items[0];
    const insuranceInformation = data.insuranceInformation;

    if (insuranceInformation) {
      this._retrieveInsuranceCardImage();

      const plan = insuranceInformation.plan;

      let insuranceData = {
        memberId: insuranceInformation.memberId,
        provider: insuranceInformation.provider.name,
        planType: plan.type,
        userPlan: plan.name,
        paymentType: data.type.code,
      };

      if (insuranceInformation.members && insuranceInformation.members.length > 0) {
        const member = insuranceInformation.members
          .filter((member) => member.role === 'POLICY_HOLDER')
          .pop();
        insuranceData.member = {
          givenName: member.name.given,
          familyName: member.name.family,
          dateOfBirth: userInfoUtil.formatToUSDate(member.dob),
          gender: member.sex,
        };
      } else {
        insuranceData.member = {};
      }

      this.setState({
        existingPaymentMethods: insuranceData,
      });
    } else {
      this.setState({
        loading: false,
        existingPaymentMethods: {
          paymentType: data.type.code,
        },
      });
    }
  };

  handleButtonClick = (nextPath) => {
    uriStorage.setCurrentPath(nextPath);
    this.props.history.push(nextPath);
  };

  isUpdatable = () => {
    return (
      this.state.existingPaymentMethods.paymentType &&
      this.state.existingPaymentMethods.paymentType !== ''
    );
  };

  renderPaymentType = (paymentMethod) => {
    let { classes } = this.props;

    return (
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4" className={classes.title}>
            <FormattedMarkdown id="paymentMethod.view.title" defaultMessage="Payment method:" />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" className={classes.type}>
            <FormattedMarkdown id="paymentMethod.view.type" defaultMessage={paymentMethod} />
          </Typography>
        </Grid>
      </Grid>
    );
  };

  renderPolicyHolderInfo = () => {
    let { classes } = this.props;
    let { givenName, familyName, dateOfBirth, gender } = this.state.existingPaymentMethods.member;

    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.title}>
            <FormattedMarkdown id="policyholder" defaultMessage={`Policy Holder`} />
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            disabled
            fullWidth
            id="givenName"
            label="First Name"
            name="givenName"
            value={givenName}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            disabled
            fullWidth
            id="familyName"
            label="Last Name"
            name="familyName"
            value={familyName}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            disabled
            fullWidth
            id="dateOfBirth"
            label="Date of Birth"
            name="dateOfBirth"
            value={dateOfBirth}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField disabled fullWidth id="gender" label="Gender" name="gender" value={gender} />
        </Grid>
      </React.Fragment>
    );
  };

  renderInsuranceInfo = () => {
    let { classes } = this.props;
    let { memberId, provider, planType, userPlan, member } = this.state.existingPaymentMethods;

    return (
      <React.Fragment>
        <div className={classes.validation}>
          <div className={classes.form}>
            <div className={classes.formInput}>
              <Grid container spacing={3}>
                {member.givenName && this.renderPolicyHolderInfo()}

                <Grid item xs={12}>
                  <Typography variant="h4" className={classes.title}>
                    <FormattedMessage id="insurance" defaultMessage={`Insurance`} />
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    id="memberId"
                    label="Member ID"
                    name="memberId"
                    value={memberId}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Insurance Provider"
                    id="provider"
                    name="provider"
                    disabled
                    value={provider}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="providerPlan"
                    name="providerPlan"
                    fullWidth
                    disabled
                    label="Insurance Plan Type"
                    value={planType}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="userPlan"
                    fullWidth
                    disabled
                    label="Your Insurance Plan"
                    value={userPlan}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderPaymentMethod = () => {
    let { classes } = this.props;

    if (this.state.loading) {
      return (
        <div className={classes.progressBox}>
          <CircularProgress classes={{ circle: classes.progress }} />
        </div>
      );
    }

    switch (this.state.existingPaymentMethods.paymentType) {
      case 'insurance':
        return (
          <React.Fragment>
            {this.renderPaymentType('Insurance')}
            {this.renderInsuranceInfo()}
            {this.renderInsuranceCardImage()}
          </React.Fragment>
        );
      case 'outofpocket':
        return this.renderPaymentType('Out-of-pocket');
      default:
        return (
          <React.Fragment>
            {this.state.loading ? (
              <div className={classes.progressBox}>
                <CircularProgress classes={{ circle: classes.progress }} />
              </div>
            ) : (
              this.renderPaymentType('Unknown')
            )}
          </React.Fragment>
        );
    }
  };

  renderInsuranceCardImage = () => {
    let { classes } = this.props;
    let { insuranceCard } = this.state;

    if (!insuranceCard || insuranceCard.items.length === 0) return null;

    const firstImage = insuranceCard.items[0].value;

    return (
      <div>
        <Typography variant="h4" className={classes.title}>
          <FormattedMarkdown id="insurance.image" defaultMessage={`Insurance Card`} />
        </Typography>
        <img
          className={classes.insuranceImage}
          src={`data:image/png;base64, ${firstImage}`}
          alt={'Your provided insurance card.'}
        />
      </div>
    );
  };

  render() {
    let { classes } = this.props;
    let { loading, assistantMessage } = this.state;

    return (
      <PageContainer loading={loading}>
        <div>
          <ChatBubble messageId={assistantMessage.id} message={assistantMessage} />
        </div>

        <div className={classes.contentBox}>
          <div className={classes.dataDisplay}>{this.renderPaymentMethod()}</div>

          {!loading && (
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              className={classes.buttonGroup}
            >
              <Grid item xs={12} sm={6} className={classes.buttonGridItem}>
                <div className={classes.button}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!this.isUpdatable()}
                    onClick={() => this.handleButtonClick(ACCOUNT_PAYMENT_METHOD_UPDATE_ROUTE)}
                  >
                    Update
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.buttonGridItem}>
                <div className={classes.button}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    onClick={() => this.handleButtonClick(QUINN_ROUTE)}
                  >
                    Continue
                  </Button>
                </div>
              </Grid>
            </Grid>
          )}
        </div>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(PaymentMethod);
