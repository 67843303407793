import { DecodedBloc } from '../shared/DecodedComponent/bloc';
import { authService } from '../../utils/auth';
import { providerStorage } from '../../utils/provider.qs';

export class Bloc extends DecodedBloc {
  appointmentApi;

  constructor(props) {
    super({ ...props });
    this.appointmentApi = props.appointmentApi;
  }

  initialise = () => {
    this.__makeInitialised({});
    authService.logout();
    providerStorage.clearProvider();
  };
}

export class Constants {}

export class BlocEvent {
  static NAVIGATE_TO = 'NAVIGATE_TO';
}
