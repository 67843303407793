import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PageContainer from "../../common/PageContainer";
import {SelectValidator, TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { Select } from '../../Registration/components/Select';
import { FormattedMessage } from 'react-intl';
import ChatBubble from '../../shared/ChatBubble';
import {DateMask, PhoneTextMask, ZipTextMask} from '../../shared/InputTextMask';
import { STATES } from './const';
import { authService } from '../../../utils/auth';
import { FormattedMarkdown, MapsAutocompleteField } from '@decodedhealth/react-library';
import {useGuarantorCodesets, useRaceCodesets} from "../../../utils/services/register.api";
import MenuItem from "@mui/material/MenuItem";
import {ScrollableBox, ScrollableQuinnContainer, ScrollableWrapper} from "../../common/ScrollableContainer";
import DecodedButton, {DecodedSubmitButton} from "../../common/DecodedButton";
import {GENDER_MANDATORY_OPTIONS} from "../../../utils/user";
const useStyles = makeStyles((theme) => ({
  validation: {
    flex: '1',
    width: '100%',
  },
  title: {
    paddingTop: '1em',
    color: '#FF7700',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100%',
  },
  formInput: {
    flex: '1',
  },
  buttonGroup: {
    paddingTop: '2em',
  },
  button: {
    width: '100%',
    padding: '1em',
  },
}));

export const ResponsibleParty = ({ data, submitText, loading, handleSubmit, handleBack, setDirty }) => {

  const isLoading = () => {
    return loading || authService.isLoading();
  };

  const classes = useStyles();
  const [formState, setFormState] = React.useState({
    relationship: data.relationship ?? 'SELF',
    firstName: data.firstName ?? '',
    lastName: data.lastName ?? '',
    gender: data.gender ?? '',
    dateOfBirth: data.dateOfBirth ?? '',
    phoneNumber: data.phoneNumber ?? '',
    address: data.address ?? '',
    city: data.city ?? '',
    postCode: data.postCode ?? '',
    state: data.state ?? '',
  });
  const {
    relationship,
    firstName,
    lastName,
    phoneNumber,
    address,
    city,
    postCode,
    state,
    gender,
    dateOfBirth,
  } = formState;

  const handleAutoCompleteChange = React.useCallback((formValues) => {
    setFormState((prevValue) => ({
      ...prevValue,
      address: formValues.address,
      city: formValues.city,
      state: formValues.administrativeArea,
      postCode: formValues.postalCode,
    }));
  }, []);

  const [readOnly, setReadOnly] = React.useState(true);
  const onEdit = () => {
    setReadOnly(false);
    setDirty();
  };

  const [relationshipList, relationshipsLoaded] = useGuarantorCodesets();

  const handleTextChange = (e) => {
    setFormState((state) => ({ ...state, [e.target.name]: e.target.value }));
  };
  const onSubmit = (e) => {
    handleSubmit(formState)
  }
  return (
    <PageContainer loading={loading} onBack={handleBack}>
      <ScrollableQuinnContainer
          messageId={"registration.user.bubble.responsibleParty"}
          message={"Please let us know about the responsible party."}>
        <ValidatorForm autoComplete="off" onSubmit={onSubmit} className={classes.validation}>
        <div className={classes.form}>
          <div className={classes.formInput}>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" className={classes.title}>
                  <FormattedMarkdown
                    id="registration.user.step.5"
                    defaultMessage="Responsible Party"
                  />
                </Typography>
              </Grid>

              {relationshipsLoaded && (
                  <Grid item xs={12} sm={12}>
                    <SelectValidator
                        fullWidth
                        select
                        id="relationship"
                        name="relationship"
                        label={`Patient’s relationship to the responsible party`}
                        value={relationship}
                        onChange={handleTextChange}
                        disabled={readOnly}
                    >
                      {relationshipList.map((r, i) => (
                          <MenuItem key={i} value={r.code}>
                            {r.value}
                          </MenuItem>
                      ))}
                    </SelectValidator>
                  </Grid>
              )}

              {relationship && relationship !== 'SELF' ? (
                <>
                  <Grid item xs={6} sm={6}>
                    <TextValidator
                      required
                      fullWidth
                      autoFocus={true}
                      id="firstName"
                      label="First Name"
                      name="firstName"
                      autoComplete="off"
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      value={firstName}
                      validators={['isValidName']}
                      errorMessages={[
                        <FormattedMessage
                          id="registration.field.error.patient.name.given"
                          defaultMessage={'Invalid name.'}
                        />,
                      ]}
                      onChange={handleTextChange}
                      disabled={readOnly}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <TextValidator
                      required
                      fullWidth
                      id="lastName"
                      label={
                        <FormattedMessage
                          id="registration.field.label.lastName"
                          defaultMessage={'Last Name'}
                        />
                      }
                      type="text"
                      name="lastName"
                      autoComplete="off"
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      value={lastName}
                      validators={['isValidName']}
                      errorMessages={[
                        <FormattedMessage
                          id="registration.field.error.patient.name.family"
                          defaultMessage={'Invalid name.'}
                        />,
                      ]}
                      onChange={handleTextChange}
                      disabled={readOnly}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Select
                        id="gender"
                        name="gender"
                        label="Gender"
                        value={gender}
                        required
                        onChange={handleTextChange}
                        options={GENDER_MANDATORY_OPTIONS}
                        getItemLabel={(item) => item.label}
                        getItemValue={(item) => item.value}
                        disabled={readOnly}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextValidator
                        required
                        fullWidth
                        id="dateOfBirth"
                        label={
                          <FormattedMessage
                              id="registration.field.label.dob"
                              defaultMessage={'Date of Birth'}
                          />
                        }
                        name="dateOfBirth"
                        placeholder="MM/dd/yyyy e.g. 12/31/2000"
                        autoComplete="bday"
                        value={dateOfBirth}
                        onChange={handleTextChange}
                        validators={['isDateValid']}
                        errorMessages={[
                          <FormattedMessage
                              id="registration.field.error.dob"
                              defaultMessage={'Invalid date of birth'}
                          />,
                        ]}
                        InputProps={{
                          inputComponent: DateMask,
                        }}
                        inputProps={{ inputMode: 'numeric' }}
                        disabled={readOnly}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextValidator
                      fullWidth
                      id="phoneNumber"
                      label="Phone"
                      required
                      name="phoneNumber"
                      autoComplete="off"
                      inputProps={{
                        autoComplete: 'off',
                        inputMode: 'tel',
                      }}
                      value={phoneNumber}
                      onChange={handleTextChange}
                      placeholder="e.g. (202) 555-1234"
                      validators={['matchRegexp:^(\\([0-9]{3}\\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$']}
                      errorMessages={[
                        <FormattedMessage
                          id="registration.field.error.mobile"
                          defaultMessage={'Invalid phone number'}
                        />,
                      ]}
                      InputProps={{
                        inputComponent: PhoneTextMask,
                      }}
                      disabled={readOnly}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                  <MapsAutocompleteField
                      restrictCountry="US"
                      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                      id="address"
                      label="Address"
                      name="address"
                      value={address}
                      onAutocompleteChange={handleAutoCompleteChange}
                      onInputTextChange={handleTextChange}
                      readOnly={readOnly}
                  />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextValidator
                      fullWidth
                      autoFocus={true}
                      id="city"
                      label="City"
                      name="city"
                      autoComplete="off"
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      value={city}
                      onChange={handleTextChange}
                      disabled={readOnly}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Select
                      id="state"
                      name="state"
                      label="State"
                      value={state}
                      onChange={handleTextChange}
                      options={STATES}
                      getItemLabel={(item) => item.display}
                      getItemValue={(item) => item.code}
                      disabled={readOnly}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextValidator
                      id="postCode"
                      name="postCode"
                      label="Zip Code"
                      fullWidth
                      value={postCode}
                      onChange={handleTextChange}
                      validators={['matchRegexp:(^\\d{5}$)|(^\\d{9}$)|(^\\d{5}-\\d{4}$)']}
                      errorMessages={['Invalid zip code']}
                      InputProps={{
                        inputComponent: ZipTextMask,
                      }}
                      disabled={readOnly}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </div>

          <Grid
              container
              spacing={2}
              justifyContent="flex-start"
              className={classes.buttonGroup}
          >
            {readOnly && <Grid item xs={12} sm={6} className={classes.button}>
              <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  disabled={isLoading()}
                  onClick={onEdit}
              >
                {isLoading() ? (
                    <CircularProgress size="1.5em"/>
                ) : (
                    <FormattedMessage
                        id="registration.button.label.edit"
                        defaultMessage="Edit"
                    />
                )}
              </Button>
            </Grid>}
            <Grid item xs={12} sm={6} className={classes.button}>
              <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isLoading()}
              >
                {isLoading() ? (
                    <CircularProgress size="1.5em" />
                ) : (
                    <FormattedMessage
                        id="registration.button.label.submit"
                        defaultMessage="Next"
                    />
                )}
              </Button>
            </Grid>
          </Grid>

        </div>
        </ValidatorForm>
      </ScrollableQuinnContainer>
    </PageContainer>
  );
};
