import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import DecodedComponent from '../../shared/DecodedComponent';
import InsuranceContext from './context';
import { withRouter } from 'react-router-dom';
import InsuranceLoading from './components/InsuranceLoading';
import InsuranceList from './components/InsuranceList';
import InsuranceAccept from './components/InsuranceAccept';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

class Insurance extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = props.bloc;
    this.state = { initialised: false };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    let context = { bloc: this.bloc };

    const component = this.__render();

    return (
      <InsuranceContext.Provider value={context}>
        <>{component}</>
      </InsuranceContext.Provider>
    );
  }

  __render() {
    const { initialised, insurances, confirm } = this.state;

    if (confirm) {
      return (
        <>
          <InsuranceAccept />
        </>
      );
    }

    if (initialised && insurances) {
      return (
        <>
          <InsuranceList />
        </>
      );
    }

    return (
      <>
        <InsuranceLoading />
      </>
    );
  }
}

export default withStyles(styles)(withRouter(Insurance));
