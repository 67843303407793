import { notificationService } from '../../utils/notification';
import { DecodedBloc } from '../shared/DecodedComponent/bloc';
import { uriStorage } from '../../utils/storage';
import { authService } from '../../utils/auth';
import { providerStorage } from '../../utils/provider.qs';
import { organisationsApi } from '../../utils/services/organisations.api';

export class Bloc extends DecodedBloc {
  constructor(organisationId) {
    super({ organisationId: organisationId });

    this.__initialise(organisationId);
  }

  __initialise = (organisationId) => {
    authService.logout().then(() => {
      uriStorage.clearPath();
      providerStorage.clearProvider();
    });

    organisationsApi
      .organisation(organisationId)
      .then(
        (value) => {
          const organisation = value.data;
          this.__updateSubject({ organisation: organisation });
        },
        (reason) => {
          notificationService.error('Error loading organisation.');
        },
      )
      .finally(() => {
        this.__updateSubject({ initialising: false });
      });
  };
}

export class BlocEvent {
  static INITIALISED = 'INITIALISED';
}
