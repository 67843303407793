import React from 'react';

import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { withRouter } from 'react-router-dom';
import PageContainer from '../common/PageContainer';
import { FormControl, Grid, InputLabel, Select, styled, TextField } from '@mui/material';
import DecodedComponent from '../shared/DecodedComponent';
import { Bloc } from './bloc';
import ScrollableContainer, {
  PageContent,
  PageFooter,
  ScrollableQuinnContainer,
} from '../common/ScrollableContainer';
import DecodedButton from '../common/DecodedButton';
import MenuItem from '@mui/material/MenuItem';
import Verification from './Verification';
import Administration from './Administration';

const styles = (theme) => ({});

const initState = { initialising: true, providerId: '', terminalId: '' };

const H1TextTitleSecondary = styled(Typography, { variant: 'h1' })(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '40px !important',
  lineHeight: '32px',
}));

class KioskAdmin extends DecodedComponent {
  constructor(props) {
    super(props);
    this.state = { ...initState };
    this.bloc = new Bloc(props);
  }

  __handleTextChange = (e) => {
    this.bloc.setValue(e.target.name, e.target.value);
  };

  render() {
    const { initialising, token } = this.state;

    if (initialising) {
      return (
        <PageContainer loading={initialising}>
          <ScrollableContainer applyFormat={true}></ScrollableContainer>
        </PageContainer>
      );
    }

    if (!token) {
      return (
        <PageContainer loading={initialising}>
          <ScrollableContainer applyFormat={true}>
            <Verification bloc={this.bloc} />
          </ScrollableContainer>
        </PageContainer>
      );
    }

    return (
      <PageContainer loading={initialising}>
        <ScrollableContainer applyFormat={true}>
          <Administration bloc={this.bloc} />
        </ScrollableContainer>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(withRouter(KioskAdmin));
