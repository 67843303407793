const GENDER_OPTIONS = [
  {
    value: 'M',
    label: 'Male',
  },
  {
    value: 'F',
    label: 'Female',
  },
  {
    value: 'U',
    label: 'Unspecified',
  },
];
const GENDER_MANDATORY_OPTIONS = [
  {
    value: 'M',
    label: 'Male',
  },
  {
    value: 'F',
    label: 'Female',
  },
];

const STATE_OPTIONS = [
  { code: 'AL', display: 'Alabama' },
  { code: 'AK', display: 'Alaska' },
  { code: 'AZ', display: 'Arizona' },
  { code: 'AR', display: 'Arkansas' },
  { code: 'CA', display: 'California' },
  { code: 'CO', display: 'Colorado' },
  { code: 'CT', display: 'Connecticut' },
  { code: 'DE', display: 'Delaware' },
  { code: 'DC', display: 'District Of Columbia' },
  { code: 'FL', display: 'Florida' },
  { code: 'GA', display: 'Georgia' },
  { code: 'HI', display: 'Hawaii' },
  { code: 'ID', display: 'Idaho' },
  { code: 'IL', display: 'Illinois' },
  { code: 'IN', display: 'Indiana' },
  { code: 'IA', display: 'Iowa' },
  { code: 'KS', display: 'Kansas' },
  { code: 'KY', display: 'Kentucky' },
  { code: 'LA', display: 'Louisiana' },
  { code: 'ME', display: 'Maine' },
  { code: 'MD', display: 'Maryland' },
  { code: 'MA', display: 'Massachusetts' },
  { code: 'MI', display: 'Michigan' },
  { code: 'MN', display: 'Minnesota' },
  { code: 'MS', display: 'Mississippi' },
  { code: 'MO', display: 'Missouri' },
  { code: 'MT', display: 'Montana' },
  { code: 'NE', display: 'Nebraska' },
  { code: 'NV', display: 'Nevada' },
  { code: 'NH', display: 'New Hampshire' },
  { code: 'NJ', display: 'New Jersey' },
  { code: 'NM', display: 'New Mexico' },
  { code: 'NY', display: 'New York' },
  { code: 'NC', display: 'North Carolina' },
  { code: 'ND', display: 'North Dakota' },
  { code: 'OH', display: 'Ohio' },
  { code: 'OK', display: 'Oklahoma' },
  { code: 'OR', display: 'Oregon' },
  { code: 'PA', display: 'Pennsylvania' },
  { code: 'RI', display: 'Rhode Island' },
  { code: 'SC', display: 'South Carolina' },
  { code: 'SD', display: 'South Dakota' },
  { code: 'TN', display: 'Tennessee' },
  { code: 'TX', display: 'Texas' },
  { code: 'UT', display: 'Utah' },
  { code: 'VT', display: 'Vermont' },
  { code: 'VA', display: 'Virginia' },
  { code: 'WA', display: 'Washington' },
  { code: 'WV', display: 'West Virginia' },
  { code: 'WI', display: 'Wisconsin' },
  { code: 'WY', display: 'Wyoming' },
];

const DATE_FORMAT = 'yyyy-MM-dd';
const HOUR_FORMAT = 'h aa';

export { GENDER_OPTIONS, GENDER_MANDATORY_OPTIONS, STATE_OPTIONS, DATE_FORMAT, HOUR_FORMAT };

class UserInfoUtil {
  formatDate(date) {
    let dateComponents = date.split('/');
    return `${dateComponents[2]}-${dateComponents[0]}-${dateComponents[1]}`;
  }

  formatToUSDate(date) {
    let dateComponents = date.split('-');
    return `${dateComponents[1]}/${dateComponents[2]}/${dateComponents[0]}`;
  }

  validName = (name) => {
    return /^\b([A-Za-zÀ-ÿ]+[-,a-z. '0-9]*[ ]*)+$/gm.test(name);
  };

  formatName = (firstName, lastName) => {
    return {
      given: firstName ? firstName.trim() : '',
      family: lastName ? lastName.trim() : '',
    };
  };
}

export const userInfoUtil = new UserInfoUtil();
