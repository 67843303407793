import { DecodedBloc } from '../shared/DecodedComponent/bloc';

export class Bloc extends DecodedBloc {
  constructor(appointmentId) {
    super({});
    this.__initialise();
  }

  __initialise = () => {
    this.__updateSubject({ initialising: false });
  };
}

export class BlocEvent {
  static INITIALISED = 'INITIALISED';
}
